import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import galleta from "js-cookie";
import { getSecretCode } from "views/pages/synchronous-communication/api/SecretCode";

export default function ConferenceRedirect() {
  const { roomName, patientId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getConferenceToken = async ({ userToken, userId, roomName }) => {
    const token = await getSecretCode(userToken, roomName, userId);
    return token;
  };

  useEffect(() => {
    try {
      let userInfo = galleta.get("user"); // Obtener token de autenticación

      if (typeof userInfo === "string") {
        userInfo = JSON.parse(userInfo);
      }

      if (userInfo) {
        getConferenceToken({
          userToken: userInfo.user.token,
          userId: patientId,
          roomName: roomName,
        }).then((token) => {
          localStorage.setItem(
            "videoconferenceSettings",
            JSON.stringify({
              id: patientId,
              name: roomName,
              username: userInfo.user.result[0].names ?? "Usuario",
              token: token,
            })
          );
          setError(null);
          setIsLoading(false);
        });
      } else {
        setError("No se pudo obtener la información del usuario.");
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }, [setError, setIsLoading, patientId, roomName]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  } else {
    return <Redirect to="/atención/videoconferencia" />;
  }
}
