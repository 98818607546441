import React, { useState } from 'react'
import {
    Button,
    FormGroup,
    Form,
    Input,
    Modal,
    Label,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    Card,
    CardColumns,
    CardImg,
    CardTitle
} from "reactstrap";
import InjurySelector from './InjurySelector';


const AddMarkerForm = (props) => {
    const initialFormState = {
        id: null,
        idAnamnesis: null,
        form: 1,
        length: 0,
        width: 0,
        height: 0,
        color: 1,
        surface: 1,
        consistency: 1,
        evolution_time: null,
        evolution_form: 1,
        symptomatology: 1,
        edge: 1,
        localization: 1,
        observation: '',
        position_x: 0,
        position_y: 0,
        position_z: 0,
        injury: 1,
        foto: null
    }
    const [marker, setMarker] = useState(initialFormState)

    const [modalInjury, setModalInjury] = useState(false);
    const toggleInjury = () => setModalInjury(!modalInjury);

    const handleInjuryModal = () => {
        toggleInjury()
    }

    const [modalImage, setModalImage] = useState(false);
    const toggleImage = () => setModalImage(!modalImage);



    const handleImageModal = () => {
        toggleImage()
    }

    const handleImageTag = (e) => {
        toggleImage()
        marker.foto = e.target.id
        console.log(e.target.id)
    }

    const handleInjurySelector = (event) => {
        const { name, id } = event.target
        setMarker({ ...marker, [name]: id })
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setMarker({ ...marker, [name]: value })
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={props.adding}
                size="xl">
                <ModalHeader >
                    Descripción de la lesión
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Form onSubmit={event => {
                        event.preventDefault()
                        props.addMarker(marker)
                        setMarker(initialFormState)
                    }}
                        role="form">
                        <FormGroup className="row">
                            <Col>
                                <Label /* lg="12" */ className="form-control-label">Selección de patologías</Label>{" "}
                                <Col>
                                    <Button size="lg" color="info"
                                        onClick={() => handleInjuryModal()}>Abrir selector
                                        </Button>
                                </Col>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Seleccionar Imagen</Label>
                                <Col>
                                    <Button size="lg" color="primary"
                                        onClick={() => handleImageModal()}><i className="fas fa-camera"></i>
                                    </Button>
                                </Col>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Forma</Label>
                                <Input
                                    id="form"
                                    type="select"
                                    name="form"
                                    defaultValue={marker.form}
                                    onChange={handleInputChange}
                                >
                                    {props.formData.map(form => (
                                        <option
                                            key={form.name}
                                            value={form.form_id}
                                            onFocus={(e) => console.log(e.target)}
                                            id={form.name}
                                            data-toggle="tooltip"
                                            title={form.description}
                                        >
                                            {form.name}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Color</Label>
                                <Input
                                    id="color"
                                    type="select"
                                    name="color"
                                    defaultValue={marker.color}
                                    onChange={handleInputChange}
                                >
                                    {props.colorData.map(color => (
                                        <option key={color.value} value={color.color_id} id={color.value} >{color.value}</option>
                                    ))}

                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Superficie</Label>
                                <Input
                                    id="surface"
                                    type="select"
                                    name="surface"
                                    defaultValue={marker.surface}
                                    onChange={handleInputChange}
                                >
                                    {props.surfaceData.map(surface => (
                                        <option key={surface.value} value={surface.surface_id}>{surface.value}</option>
                                    ))}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label" >Tamaño</Label>
                                <InputGroup>
                                    <Input
                                        type="number"
                                        min={1}
                                        max={150}
                                        step="1"
                                        name="length"
                                        placeholder="largo"
                                        /* defaultValue={0} */
                                        onChange={(e) => { handleInputChange(e) }}
                                        required
                                    />
                                    <Input
                                        type="number"
                                        min={1}
                                        max={150}
                                        step="1"
                                        name="width"
                                        placeholder="ancho"
                                        /* defaultValue={0} */
                                        onChange={(e) => { handleInputChange(e) }}
                                        required
                                    />
                                    <Input
                                        type="number"
                                        min={1}
                                        max={150}
                                        step="1"
                                        name="height"
                                        placeholder="alto"
                                        /* defaultValue={0} */
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    <InputGroupAddon addonType="append">mm</InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Consistencia</Label>
                                <Input
                                    id="consistency"
                                    type="select"
                                    name="consistency"
                                    defaultValue={marker.consistency}
                                    onChange={handleInputChange}
                                >
                                    {props.consistencyData.map(consistency => (
                                        <option key={consistency.name} value={consistency.consistency_id}>{consistency.name}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Bordes</Label>
                                <Input
                                    id="edge"
                                    type="select"
                                    name="edge"
                                    defaultValue={marker.edge}
                                    onChange={handleInputChange}
                                >
                                    {props.edgeData.map(edge => (
                                        <option key={edge.name} value={edge.edge_id}>{edge.name}</option>
                                    ))}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Sintomatología</Label>
                                <Input
                                    id="symptomatology"
                                    type="select"
                                    name="symptomatology"
                                    defaultValue={marker.symptomatology}
                                    onChange={handleInputChange}>
                                    {props.symptomatologyData.map(symptomatology => (
                                        <option key={symptomatology.value} value={symptomatology.symptomatology_id}>{symptomatology.value}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Forma de evolución</Label>
                                <Input
                                    id="evolution_form"
                                    type="select"
                                    name="evolution_form"
                                    defaultValue={marker.evolution_form}
                                    onChange={handleInputChange}>
                                    {props.evolutionFormData.map(evolutionForm => (
                                        <option key={evolutionForm.value} value={evolutionForm.evolution_form_id}>{evolutionForm.value}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Tiempo de evolución</Label>
                                <Input
                                    id="evolution_time"
                                    type="text"
                                    name="evolution_time"
                                    maxLength="20"
                                    defaultValue={marker.evolution_time}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Localización</Label>
                                <Input
                                    id="localization"
                                    type="select"
                                    name="localization"
                                    defaultValue={marker.localization}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                >
                                    {props.localizationData.map(localization => (
                                        <option key={localization.value} value={localization.localization_id}>{localization.value}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Label className="form-control-label">Observaciones</Label>
                                <Input
                                    id="example-text-input1"
                                    type="textarea"
                                    name="observation"
                                    maxLength="200"
                                    defaultValue={marker.observation}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">

                        </FormGroup>
                        <ModalFooter>
                            <Button type="submit" color="primary" >
                                Guardar
                            </Button>
                            <Button color="secondary"
                                onClick={() => props.setAdding(false)}>Cancelar
                             </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
            <InjurySelector modalInjury={modalInjury} toggleInjury={toggleInjury} handleInjurySelector={handleInjurySelector} />
            <Modal size='lg' centered={true} isOpen={modalImage} toggle={toggleImage}>
                <ModalHeader toggle={toggleImage} >Imagenes</ModalHeader>
                <ModalBody>
                    <CardColumns>
                        {props.image.map((image) => (
                            <Card key={image.foto_id} >
                                <CardImg onClick={(e) => handleImageTag(e)} id={image.foto_id} top width="50%" height="200" src={process.env.REACT_APP_API_URL + `${image.url}` + "/" + `${image.foto_nombre}`} alt={image.foto_nombre} />
                                {/*  <Input
                                            id={image.foto_id}
                                            type="image"
                                            name="foto"
                                            defaultValue={marker.foto}
                                            onChange={handleInputChange}
                                        /> */}
                                <CardTitle tag="h5">{image.foto_nombre}</CardTitle>
                            </Card>
                        ))}
                    </CardColumns>
                </ModalBody>
                {/* <ModalFooter>
                            <Button color="primary" onClick={() => console.log('boton')}>Agregar seleccionada</Button>{' '}
                            <Button color="secundary" onClick={toggleImage}>Cancelar</Button>
                        </ModalFooter> */}
            </Modal>
        </>
    )

}
export default AddMarkerForm