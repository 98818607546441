import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Label, FormGroup, Input, Button, Col, Row } from 'reactstrap'
import CoronalCavities from './status/coronal-cavitie'
import RadicularCavities from './status/radicular-cavitie'
import Restoration from './status/restoration'
import NonCariousInjury from './status/non-carious-injury'

const ToothStatus = (props, ref) => {
    const { toothSelected, toothStatus, toothState, setToothStatus, setTeethStatus, teethStatus, teethStatusList, setToothState, teethSelected, setMessage, setIsAnimated } = props

    const checkboxList = [
        { label: 'Sano', id: 'healthy' },
        { label: 'Caries Coronal', id: 'coronal_cavitie' },
        { label: 'Caries Radicular', id: 'radicular_cavitie' },
        { label: 'Restauración', id: 'restoration' },
        { label: 'Lesión no cariosa', id: 'non_carious_injury' },
        { label: 'Tratado endodónticamente', id: 'endodontically_treated' },
    ]

    const defaultStatus = {
        healthy: { checked: false, disabled: false },
        coronal_cavitie: { checked: false, disabled: false },
        radicular_cavitie: { checked: false, disabled: false },
        restoration: { checked: false, disabled: false },
        non_carious_injury: { checked: false, disabled: false },
        endodontically_treated: { checked: false, disabled: false },
        prosthesis: { checked: false, disabled: false }
    }

    const checkboxListFirstLevel = [
        { label: 'Completo', id: '2' },
        { label: 'Fracturado', id: '3' },
        { label: 'Resto radicular', id: '4' },
        { label: 'Implante', id: '5' },
        { label: 'Prótesis fija', id: '6' },
        { label: 'Ausente', id: '1' },
    ]

    const ITEMS_ID = ['coronal_cavitie', 'radicular_cavitie', 'restoration', 'non_carious_injury']

    const [status, setStatus] = useState(defaultStatus)

    const defaultCoronal = { surface: { oclusal: false, vestibular: false, palatino: false, mesial: false, distal: false }, classification: "", activity: "" }
    const defaultRadicular = { surface: { vestibular: false, palatino: false, mesial: false, distal: false }, classification: "", activity: "" }
    const defaultRestoration = { surface: { coronal_oclusal: false, coronal_vestibular: false, coronal_palatino: false, coronal_mesial: false, coronal_distal: false, radicular_vestibular: false, radicular_palatino: false, radicular_mesial: false, radicular_distal: false }, material: "", status: "" }
    const defaultNonCarious = { type: "", surface: { coronal_oclusal: false, coronal_vestibular: false, coronal_palatino: false, coronal_mesial: false, coronal_distal: false, radicular_vestibular: false, radicular_palatino: false, radicular_mesial: false, radicular_distal: false } }

    const [coronal, setCoronal] = useState(defaultCoronal)
    const [radicular, setRadicular] = useState(defaultRadicular)
    const [restoration, setRestoration] = useState(defaultRestoration)
    const [nonCarious, setNonCarious] = useState(defaultNonCarious)

    const handleInputCheckbox = (e) => {
        showMessage();
        handleChange(e);
    }

    useEffect(() => {
        setStatus(defaultStatus)
        setCoronal(defaultCoronal)
        setRadicular(defaultRadicular)
        setRestoration(defaultRestoration)
        setNonCarious(defaultNonCarious)
    }, [toothSelected])

    useImperativeHandle(ref, () => ({
        handleStatus
    }));

    const checkBoxStatus = (toothState) => {
        if (toothState === 2) {
            setStatus({
                ...status,
                healthy: { checked: !!toothStatus.healthy, disabled: false },
                coronal_cavitie: { checked: toothStatus.coronal_cavitie !== null && JSON.stringify(defaultCoronal) !== toothStatus.coronal_cavitie, disabled: toothStatus.healthy },
                radicular_cavitie: { checked: toothStatus.radicular_cavitie !== null && JSON.stringify(defaultRadicular) !== toothStatus.radicular_cavitie, disabled: toothStatus.healthy },
                restoration: { checked: toothStatus.restoration !== null && JSON.stringify(defaultRestoration) !== toothStatus.restoration, disabled: toothStatus.healthy },
                non_carious_injury: { checked: toothStatus.non_carious_injury !== null && JSON.stringify(defaultNonCarious) !== toothStatus.non_carious_injury, disabled: toothStatus.healthy },
                endodontically_treated: { checked: !!toothStatus.endodontically_treated, disabled: toothStatus.healthy },
                prosthesis: { checked: !!toothStatus.prosthesis, disabled: false }
            })
        }
        else if (toothState === 6) {
            setStatus({
                ...status,
                healthy: { checked: false, disabled: true },
                coronal_cavitie: { checked: false, disabled: true },
                radicular_cavitie: { checked: toothStatus.radicular_cavitie !== null && JSON.stringify(defaultRadicular) !== toothStatus.radicular_cavitie, disabled: false },
                restoration: { checked: false, disabled: true },
                non_carious_injury: { checked: false, disabled: true },
                endodontically_treated: { checked: !!toothStatus.endodontically_treated, disabled: false },
            })
        }
        else if (toothState === 5) {
            setStatus({
                ...status,
                healthy: { checked: false, disabled: true },
                coronal_cavitie: { checked: false, disabled: true },
                radicular_cavitie: { checked: false, disabled: true },
                restoration: { checked: false, disabled: true },
                non_carious_injury: { checked: false, disabled: true },
                endodontically_treated: { checked: false, disabled: true },
            })
        }
        else if (toothState === 3) {
            setStatus({
                ...status,
                healthy: { checked: false, disabled: true },
                coronal_cavitie: { checked: toothStatus.coronal_cavitie !== null && JSON.stringify(defaultCoronal) !== toothStatus.coronal_cavitie, disabled: false },
                radicular_cavitie: { checked: toothStatus.radicular_cavitie !== null && JSON.stringify(defaultRadicular) !== toothStatus.radicular_cavitie, disabled: false },
                restoration: { checked: toothStatus.restoration !== null && JSON.stringify(defaultRestoration) !== toothStatus.restoration, disabled: false },
                non_carious_injury: { checked: toothStatus.non_carious_injury !== null && JSON.stringify(defaultNonCarious) !== toothStatus.non_carious_injury, disabled: false },
                endodontically_treated: { checked: !!toothStatus.endodontically_treated, disabled: false },
            })
        }
        else if (toothState === 4) {
            setStatus({
                ...status,
                healthy: { checked: false, disabled: true },
                coronal_cavitie: { checked: false, disabled: true },
                radicular_cavitie: { checked: toothStatus.radicular_cavitie !== null && JSON.stringify(defaultRadicular) !== toothStatus.radicular_cavitie, disabled: false },
                restoration: { checked: toothStatus.restoration !== null && JSON.stringify(defaultRestoration) !== toothStatus.restoration, disabled: false },
                non_carious_injury: { checked: toothStatus.non_carious_injury !== null && JSON.stringify(defaultNonCarious) !== toothStatus.non_carious_injury, disabled: false },
                endodontically_treated: { checked: !!toothStatus.endodontically_treated, disabled: false },
            })
        }
    }

    const disableNonHealthy = (e) => {
        const { id, checked } = e.target
        checked ?
            setStatus({
                ...status,
                healthy: { checked: checked, disabled: false },
                coronal_cavitie: { checked: false, disabled: true },
                radicular_cavitie: { checked: false, disabled: true },
                restoration: { checked: false, disabled: true },
                non_carious_injury: { checked: false, disabled: true },
                endodontically_treated: { checked: false, disabled: true },
            })
            :
            setStatus({
                ...status,
                healthy: { checked: checked, disabled: false },
                coronal_cavitie: { checked: false, disabled: false },
                radicular_cavitie: { checked: false, disabled: false },
                restoration: { checked: false, disabled: false },
                non_carious_injury: { checked: false, disabled: false },
                endodontically_treated: { checked: false, disabled: false },
            })
    }

    const disableHealthy = (e) => {
        const { id, checked, disabled } = e.target
        !checked
            & Object.values(status).filter(item => item.id !== id & item.checked === true).length === 1
            ?
            setStatus({ ...status, healthy: { checked: false, disabled: false }, [id]: { checked: checked, disabled: false } })
            :
            setStatus({ ...status, healthy: { checked: false, disabled: true }, [id]: { checked: checked, disabled: false } })
    }

    const handleChange = (e) => {
        e.target.id === 'healthy' ? disableNonHealthy(e) : disableHealthy(e);
    }

    const showMessage = () => {
        setMessage('Recuerde guardar después de editar');
        setTimeout(() => {
            setMessage('');
        }, 2000);
        setIsAnimated(true);
        setTimeout(() => {
            setIsAnimated(false);
        }, 300);
    }

    useEffect(() => {
        checkBoxStatus(toothState);
    }, [toothStatus])

    const handleStatus = () => {
        if (toothState === 1) {
            setToothStatus(prevToothStatus => {
                return {
                    ...prevToothStatus,
                    healthy: false,
                    coronal_cavitie: null,
                    radicular_cavitie: null,
                    restoration: null,
                    non_carious_injury: null,
                    endodontically_treated: false,
                    prosthesis: false,
                };
            })
        }
        else {
            setToothStatus(prevToothStatus => {
                return {
                    ...prevToothStatus,
                    fdi_name: toothSelected,
                    healthy: status.healthy.checked,
                    coronal_cavitie: JSON.stringify(coronal),
                    radicular_cavitie: JSON.stringify(radicular),
                    restoration: JSON.stringify(restoration),
                    non_carious_injury: JSON.stringify(nonCarious),
                    endodontically_treated: status.endodontically_treated.checked,
                    prosthesis: toothState === 6 ? true : false,
                };
            });
        }
        setCoronalOpen(false)
        setRadicularOpen(false)
        setRestorationOpen(false)
        setNonCariousOpen(false)
    }

    useEffect(() => {
        setCoronal(status.coronal_cavitie.checked & toothStatus.coronal_cavitie !== '' & toothStatus.coronal_cavitie !== null ? JSON.parse(toothStatus?.coronal_cavitie) : defaultCoronal)
    }, [status.coronal_cavitie.checked])

    useEffect(() => {
        setRadicular(status.radicular_cavitie.checked & toothStatus.radicular_cavitie !== '' & toothStatus.radicular_cavitie !== null ? JSON.parse(toothStatus?.radicular_cavitie) : defaultRadicular)
    }, [status.radicular_cavitie.checked])

    useEffect(() => {
        setRestoration(status.restoration.checked & toothStatus.restoration !== '' & toothStatus.restoration !== null ? JSON.parse(toothStatus?.restoration) : defaultRestoration)
    }, [status.restoration.checked])

    useEffect(() => {
        setNonCarious(status.non_carious_injury.checked & toothStatus.non_carious_injury !== '' & toothStatus.non_carious_injury !== null ? JSON.parse(toothStatus?.non_carious_injury) : defaultNonCarious)
    }, [status.non_carious_injury.checked])

    const [coronalOpen, setCoronalOpen] = useState(false)
    const [radicularOpen, setRadicularOpen] = useState(false)
    const [restorationOpen, setRestorationOpen] = useState(false)
    const [nonCariousOpen, setNonCariousOpen] = useState(false)

    const handleModalOpen = (id) => {
        id === 'coronal_cavitie' && toggleCoronal()
        id === 'radicular_cavitie' && toggleRadicular()
        id === 'restoration' && toggleRestoration()
        id === 'non_carious_injury' && toggleNonCarious()
    }

    const toggleCoronal = () => {
        setCoronalOpen(!coronalOpen)
        setRadicularOpen(false)
        setRestorationOpen(false)
        setNonCariousOpen(false)
    }

    const toggleRadicular = () => {
        setRadicularOpen(!radicularOpen)
        setCoronalOpen(false)
        setRestorationOpen(false)
        setNonCariousOpen(false)
    }

    const toggleRestoration = () => {
        setRestorationOpen(!restorationOpen)
        setCoronalOpen(false)
        setRadicularOpen(false)
        setNonCariousOpen(false)
    }

    const toggleNonCarious = () => {
        setNonCariousOpen(!nonCariousOpen)
        setCoronalOpen(false)
        setRadicularOpen(false)
        setRestorationOpen(false)
    }

    const handleTeethListStatus = (id) => {
        showMessage();
        if (id != null) {
            if (teethStatusList.length != 0) {
                const newTeethStatus = teethStatus.map((tooth, index) =>
                    teethStatusList.includes(tooth.fdi_name) ? { ...tooth, tooth_state_id: parseInt(id) } : tooth
                )
                setTeethStatus(newTeethStatus);
                setToothState(parseInt(id));
            }
            checkBoxStatus(parseInt(id));
        }
    }

    return (
        <>
            <Row>
                <Col className="col-6">
                    <Label className="form-control-label">Diente {teethSelected}</Label>
                    <div
                        style={{
                            border: '1px solid',
                            borderColor: '#5e72e4',
                            borderRadius: '0.5rem',
                            margin: '10px',
                            paddingBlock: '5px',
                        }}
                    >
                        <Label className="form-control-label my-1 ml-2">Estado:</Label>
                        {checkboxListFirstLevel.map((item, index) => (
                            <FormGroup className="my-1" check key={index}>
                                <div className="custom-control custom-checkbox custom-checkbox-primary ">
                                    <Input
                                        className="custom-control-input align-middle"
                                        id={item.id}
                                        type="checkbox"
                                        name='status'
                                        checked={toothState === parseInt(item.id)}
                                        onChange={(e) => handleTeethListStatus(e.target.id)}
                                    />
                                    <label
                                        className="custom-control-label align-middle"
                                        htmlFor={item.id}
                                    >{item.label}</label>{' '}
                                </div>
                            </FormGroup>
                        ))}
                    </div>
                    {toothState !== 1 &&
                        <div
                            style={{
                                border: '1px solid',
                                borderColor: '#5e72e4',
                                borderRadius: '0.5rem',
                                margin: '10px',
                                paddingBlock: '5px',
                            }}
                        >
                            <Label className="form-control-label my-1 ml-2">Condición:</Label>
                            {checkboxList.map((item, index) => (
                                <FormGroup className="my-1" check key={index}>
                                    <div className="custom-control custom-checkbox custom-checkbox-primary ">
                                        <Input
                                            className="custom-control-input align-middle"
                                            id={item.id}
                                            type="checkbox"
                                            checked={status[item.id].checked}
                                            disabled={status[item.id].disabled}
                                            onChange={(e) => handleInputCheckbox(e)}
                                        />
                                        <label
                                            className="custom-control-label align-middle"
                                            htmlFor={item.id}
                                        >{item.label}</label>{' '}
                                        {(status[item.id].checked && item.id === ITEMS_ID.find(element => item.id === element))
                                            &&
                                            <Button className="ml-3 px-1 py-0 align-middle" onClick={() => handleModalOpen(item.id)} color="primary" size="sm">
                                                <i className="fa fa-pencil-alt"></i>
                                            </Button>
                                        }
                                    </div>
                                </FormGroup>
                            ))
                            }
                        </div>

                    }
                </Col>
                <Col className="col-6">
                    {
                        checkboxList.map((item, index) => (
                            <Fragment key={index}>
                                {item.id === 'coronal_cavitie' &&
                                    <CoronalCavities data={coronal} setData={setCoronal} name={item.label} hide={coronalOpen} />
                                }
                                {item.id === 'radicular_cavitie' &&
                                    <RadicularCavities data={radicular} setData={setRadicular} name={item.label} hide={radicularOpen} />
                                }
                                {item.id === 'restoration' &&
                                    <Restoration data={restoration} setData={setRestoration} name={item.label} hide={restorationOpen} />
                                }
                                {item.id === 'non_carious_injury' &&
                                    <NonCariousInjury data={nonCarious} setData={setNonCarious} name={item.label} hide={nonCariousOpen} />
                                }
                            </Fragment>
                        ))
                    }
                </Col>
            </Row>
        </>
    )
}
export default forwardRef(ToothStatus)