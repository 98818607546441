import { Redirect } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import {
    Alert, Button, Container, Input, Card, CardBody,
    Table, Row as FormGroup, Col
} from 'reactstrap';
import { getList } from 'helpers/ListHelper';
import moment from 'moment';
import axios from '../../../helpers/axiosConfig';
import SimpleModal from "../components/SimpleModal";
import "assets/css/app.css"
import ModalAppointment from '../appointment/modal-appointment'
import ModalListAppointments from './modal-list-appointments';


const Appointment = ({ location }) => {

    const [cleanLocation, setCleanLocation] = useState(false)
    const [loading, setLoading] = useState(true);

    let l_run = ""

    if (location?.state?.run) {
        l_run = location.state.run;
    }


    const [update, setUpdate] = useState(false)

    const modalRef = useRef()

    const setSimpleModal = (options) => {
        modalRef.current.setModal(options);
    };

    const [namesOrRunSearchTerm, setNamesOrRunSearchTerm] = useState(l_run);
    const [townSearchTerm, setTownSearchTerm] = useState("");
    const [registeredTowns, setRegisteredTowns] = useState([]);
    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [registeredStates, setRegisteredStates] = useState([]);


    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState({});

    const [newAppointment, setNewAppointment] = useState(false)

    const fetchPersons = async () => {
        const res = await getList("list/person");
        setLoading(false)
        setPersons(res);
        fetchRegisteredTownsAndStates(res);
    };

    const fetchRegisteredTownsAndStates = (persons) => {
        const registeredTowns = []
        const registeredStates = []
        persons.forEach((person) => {
            if (!registeredTowns.includes(person.town_name)) {
                registeredTowns.push(person.town_name);
            }
            if (!registeredStates.includes(person.estado)) {
                registeredStates.push(person.estado);
            }
        })
        setRegisteredStates(registeredStates);
        setRegisteredTowns(registeredTowns);
    }

    useEffect(() => {
        setTimeout(fetchPersons, 1000);
        cambiar();
        return () => {
            setCleanLocation(true);
        }
    }, []);



    const [professionals, setProfessionals] = useState([]);

    const fetchProfessionals = async () => {
        const res = await getList("user/user-dentist");
        setProfessionals(res);
    };



    const defaultProfessional = { professional_id: 0, professional_fullname: "" };
    const [professional, setProfessional] = useState(defaultProfessional);
    //const [date, setDate] = useState({ start: '', end: '' })




    /*Appointments###############################################################################*/
    const [displayModalAppointments, setDisplayModalAppointments] = useState(false);
    const [appointments, setAppointments] = useState([]);

    const showModalAppointments = async (person) => {
        setPerson(person);
        fetchAppointments(person.person_id);
        setDisplayModalAppointments(!displayModalAppointments);
    }

    const fetchAppointments = async person_id => {
        const res = await getList("consultation-appointment/" + person_id);
        setAppointments(res);
    };
    /*Appointments###############################################################################*/



    /*Appointment################################################################################*/
    const defaultAppointment = { cons_appo_id: 0, cons_appo_date: moment().format('YYYY-MM-DD'), cons_appo_start: "09:00", cons_appo_end: "11:00", professional_id: "", cons_appo_place: "", cons_appo_description: "" };
    const [displayModalAppointment, setDisplayModalAppointment] = useState(false);
    const [appointment, setAppointment] = useState(defaultAppointment);

    const showModalAppointmentToCreate = person => {
        setUpdate(false)
        setPerson(person);
        fetchProfessionals()
        setAppointment(defaultAppointment);
        //setProfessional(defaultProfessional);
        setDisplayModalAppointment(!displayModalAppointment);
    };

    const showModalAppointmentToUpdate = appointment => {
        setUpdate(true)
        fetchProfessionals()
        setAppointment(appointment);
        setProfessional({ professional_id: appointment.professional_id, professional_fullname: appointment.professional_fullname })
        setDisplayModalAppointment(!displayModalAppointment);
    }

    /*Appointment################################################################################*/


    /*Crud#######################################################################################*/
    const disableAppointment = async id => {
        await axios.put("consultation-appointment/" + id);
        setSimpleModal({})
        fetchAppointments(person.person_id);
    };




    const [ingresado, setIngresado] = useState(false)

    const cambiar = () => {
        if (location?.state?.ingresado) {
            setIngresado(!ingresado)
        }
    }

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: -2,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
            border: '10px'
        },
    };

    return (
      <Container className="mt-3">
        <SimpleModal ref={modalRef} />
        {location?.state?.ingresado && (
          <Alert
            color="success"
            isOpen={ingresado}
            toggle={() => {
              setIngresado(false);
            }}
          >
            Paciente ingresado exitosamente
          </Alert>
        )}
        <Alert
          color="success"
          isOpen={newAppointment}
          toggle={() => {
            setNewAppointment(false);
          }}
        >
          La consulta fue agendada exitosamente
        </Alert>
        <Card>
          <CardBody>
            <h1>Agendar Consulta</h1>
            <FormGroup className="row">
              <Col md="6">
                <Input
                  type="text"
                  placeholder="Buscar Paciente..."
                  autoComplete="off"
                  name="look_for_namesOrRun"
                  value={namesOrRunSearchTerm}
                  onChange={(e) => setNamesOrRunSearchTerm(e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  type="select"
                  autoComplete="off"
                  name="look_for_town"
                  value={townSearchTerm}
                  onChange={(e) => setTownSearchTerm(e.target.value)}
                >
                  <option value="Todas las comunas">Todas las comunas</option>
                  {registeredTowns.map((town) => (
                    <option value={town}>{town}</option>
                  ))}
                </Input>
              </Col>
              <Col md="3">
                <Input
                  type="select"
                  autoComplete="off"
                  name="look_for_state"
                  value={stateSearchTerm}
                  onChange={(e) => setStateSearchTerm(e.target.value)}
                >
                  <option value="Todos los estados">Todos los estados</option>
                  {registeredStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <br />
            {loading ? (
              <div className="w-100 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <div
                style={{
                  maxHeight: "30rem",
                  overflowY: "auto",
                }}
              >
                <Table className="align-items-center" hover style={style.table}>
                  <thead className="thead-light">
                    <tr style={style.th} className="text-center">
                      <th style={style.th} className="px-0" scope="col">
                        Nombre paciente
                      </th>
                      <th style={style.th} className="px-0" scope="col">
                        Run
                      </th>
                      <th style={style.th} className="px-0" scope="col">
                        Comuna
                      </th>
                      <th style={style.th} className="px-0" scope="col">
                        Estado
                      </th>
                      <th style={style.th} scope="col"></th>
                      <th style={style.th} scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {persons
                      .filter((val) => {
                        if (
                          namesOrRunSearchTerm == "" &&
                          (townSearchTerm == "" ||
                            townSearchTerm == "Todas las comunas") &&
                          (stateSearchTerm == "" ||
                            stateSearchTerm == "Todos los estados")
                        ) {
                          return val;
                        } else if (
                          namesOrRunSearchTerm == "" &&
                          (townSearchTerm == "" ||
                            townSearchTerm == "Todas las comunas")
                        ) {
                          if (
                            val.estado
                              .toLowerCase()
                              .includes(stateSearchTerm.toLowerCase())
                          )
                            return val;
                        } else if (
                          namesOrRunSearchTerm == "" &&
                          (stateSearchTerm == "" ||
                            stateSearchTerm == "Todos los estados")
                        ) {
                          if (
                            val.town_name
                              .toLowerCase()
                              .includes(townSearchTerm.toLowerCase())
                          )
                            return val;
                        } else if (
                          (townSearchTerm == "" ||
                            townSearchTerm == "Todas las comunas") &&
                          (stateSearchTerm == "" ||
                            stateSearchTerm == "Todas los estados")
                        ) {
                          if (
                            val.run.includes(namesOrRunSearchTerm) ||
                            val.fullname
                              .toLowerCase()
                              .includes(namesOrRunSearchTerm.toLowerCase())
                          )
                            return val;
                        } else if (namesOrRunSearchTerm == "") {
                          if (
                            val.estado
                              .toLowerCase()
                              .includes(stateSearchTerm.toLowerCase()) &&
                            val.town_name
                              .toLowerCase()
                              .includes(townSearchTerm.toLowerCase())
                          )
                            return val;
                        } else if (
                          townSearchTerm == "" ||
                          townSearchTerm == "Todas las comunas"
                        ) {
                          if (
                            val.run.includes(namesOrRunSearchTerm) ||
                            (val.fullname
                              .toLowerCase()
                              .includes(namesOrRunSearchTerm.toLowerCase()) &&
                              val.estado
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()))
                          )
                            return val;
                        } else if (
                          stateSearchTerm == "" ||
                          stateSearchTerm == "Todos los estados"
                        ) {
                          if (
                            val.run.includes(namesOrRunSearchTerm) ||
                            (val.fullname
                              .toLowerCase()
                              .includes(namesOrRunSearchTerm.toLowerCase()) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()))
                          )
                            return val;
                        } else if (
                          (val.run.includes(namesOrRunSearchTerm) ||
                            val.fullname
                              .toLowerCase()
                              .includes(namesOrRunSearchTerm.toLowerCase())) &&
                          val.town_name
                            .toLowerCase()
                            .includes(townSearchTerm.toLowerCase()) &&
                          val.estado
                            .toLowerCase()
                            .includes(stateSearchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((e_person) => (
                        <tr
                          className="text-center text-uppercase"
                          key={e_person.person_id}
                        >
                          <td
                            align="left"
                            className="px-2"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "250px",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {e_person.names + " " + e_person.lastnames}
                          </td>
                          <td className="px-2">{e_person.run}</td>
                          <td className="px-2">{e_person.town_name}</td>
                          <td className="px-2">
                            {e_person.estado} <br />{" "}
                            <span style={{ color: "green" }}>
                              {e_person.estado_fecha}
                            </span>
                          </td>
                          <td className="text-center px-2">
                            <Button
                              color="primary"
                              onClick={() =>
                                showModalAppointmentToCreate(e_person)
                              }
                            >
                              Agendar
                            </Button>
                          </td>
                          <td className="text-center px-2">
                            <Button
                              onClick={() => showModalAppointments(e_person)}
                            >
                              Historial
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <ModalAppointment
          person={person}
          professional={professional}
          setProfessional={setProfessional}
          professionals={professionals}
          displayModalAppointment={displayModalAppointment}
          setDisplayModalAppointment={setDisplayModalAppointment}
          appointment={appointment}
          setAppointment={setAppointment}
          fetchAppointments={fetchAppointments}
          update={update}
        />
        <ModalListAppointments
          appointments={appointments}
          displayModalAppointments={displayModalAppointments}
          setDisplayModalAppointments={setDisplayModalAppointments}
          showModalAppointmentToUpdate={showModalAppointmentToUpdate}
          disableAppointment={disableAppointment}
          setSimpleModal={setSimpleModal}
        />

        {cleanLocation && (
          <Redirect
            to={{
              pathname: "/agendar-consulta",
              state: {},
            }}
          />
        )}
      </Container>
    );
}

export default Appointment