import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Table,
  Input,
} from "reactstrap";
import { getAllSynchronousSessions } from "./api/synchronous-sessions";
import AuthHelper from "helpers/AuthHelper";
import NewSynchronousSessionButton from "./components/NewSynchronousSessionButton";
import SynchronousSessionDetails from "./components/SynchronousSessionDetails";
import moment from "moment";
import ScheduleSynchronousSessionModal from "./components/ScheduleSynchronousSessionModal";
import AccessButton from "./components/AccessButton";

export default function SynchronousSessions() {
  // data
  const [synchronousSessions, setSynchronousSessions] = useState([]);
  const [user, setUser] = useState({});
  const [selectedSession, setSelectedSession] = useState(null);

  // sessionDetails modal
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const handleOpenDetailsModal = (session) => {
    setSelectedSession(session);
    setOpenDetailsModal(true);
  };
  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedSession(null);
  };

  // Schedule session modal
  const [openScheduleSessionModal, setSchedileSessionModal] = useState(false);
  const handleOpenScheduleSessionModal = (session) => {
    setSelectedSession(session);
    setSchedileSessionModal(true);
  };
  const handleCloseScheduleSessionModal = () => {
    setSchedileSessionModal(false);
    setSelectedSession(null);
    getData();
  };

  // search filter function
  const [searchValue, setSearchValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const uniqueStatus = Array.from(
    new Set(synchronousSessions.map((s) => s.status_name))
  );
  const filteredSessions = synchronousSessions.filter((session) => {
    const patientFullName =
      `${session.patient_names} ${session.patient_lastnames}`.toLowerCase();
    const run = session.patient_run.toLowerCase();
    const statusMatch = selectedStatus
      ? session.status_name === selectedStatus
      : true;
    const searchMatch =
      patientFullName.includes(searchValue.toLowerCase()) ||
      run.includes(searchValue.toLowerCase());

    return statusMatch && searchMatch;
  });

  // fetch data
  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    setIsLoading(true);
    try {
      const username = AuthHelper.getName();
      const userId = AuthHelper.getUserId();
      const userRole = AuthHelper.getRole();
      setUser({ name: username, id: userId, role: userRole });
      const response = await getAllSynchronousSessions({ userRole: userRole });
      console.log(response);
      setSynchronousSessions(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container fluid className="mt-3">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h1>Sesiones Sincrónicas</h1>
              </Col>
              <Col xs="auto">
                <Input
                  type="text"
                  placeholder="Buscar..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Input
                  type="select"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Todas los estados</option>
                  {uniqueStatus.map((status) => (
                    <option key={status} value={status}>
                      {status.toUpperCase()}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col xs="auto">
                {user.role !== 5 && (
                  <NewSynchronousSessionButton user={user} onSave={getData} />
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Nombre del Paciente</th>
                      <th>RUN</th>
                      <th>Area</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="5">
                          <div className="d-flex justify-content-center">
                            <div className="loader" />
                          </div>
                        </td>
                      </tr>
                    ) : synchronousSessions &&
                      synchronousSessions.length > 0 ? (
                      filteredSessions.map((session) => (
                        <tr key={session.id}>
                          <td>
                            {session.patient_names.toUpperCase() +
                              " " +
                              session.patient_lastnames.toUpperCase()}
                          </td>
                          <td>{session.patient_run.toUpperCase()}</td>
                          <td>{session.medical_area_name.toUpperCase()}</td>
                          <td>
                            <Row>{session.status_name.toUpperCase()}</Row>
                            <Row>
                              {session.date
                                ? moment(session.date).format("DD-MM-YYYY")
                                : ""}
                            </Row>
                          </td>
                          <td className="d-flex">
                            <Button
                              size="sm"
                              onClick={() => handleOpenDetailsModal(session)}
                            >
                              Detalles
                            </Button>
                            {(user.role === 5 || user.role === 3) && (
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() =>
                                  handleOpenScheduleSessionModal(session)
                                }
                              >
                                <i className="fas fa-video mr-2" />
                                {session.status_id === 2
                                  ? "Reprogramar"
                                  : "Agendar"}
                              </Button>
                            )}
                            {user.role !== 5 && (
                              <AccessButton
                                size="sm"
                                conferenceTarget={{
                                  id: session.id,
                                  name: `${session.patient_names} ${session.patient_lastnames}`,
                                }}
                                username={user.name.toUpperCase()}
                                disabled={session.status_id !== 2}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No hay sesión sincrónicas registradas
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <SynchronousSessionDetails
          session={selectedSession}
          isOpen={openDetailsModal}
          onClose={handleCloseDetailsModal}
        />
        <ScheduleSynchronousSessionModal
          session={selectedSession}
          isOpen={openScheduleSessionModal}
          onClose={handleCloseScheduleSessionModal}
        />
      </Container>
    </>
  );
}
