import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Table,
  Button,
} from "reactstrap";
import Axios from "helpers/axiosConfig";

export default function SearchDentistModal({
  isOpen,
  toggle,
  setDentist,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [dentists, setDentists] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [message, setMessage] = useState("no hay coincidencias.");

  async function fetchData() {
    setIsLoading(true);
    const response = await Axios.get("user/user-dentist");
    if (response.data.error) {
      setMessage("Hubo un problema al obtener los dentistas.");
      setIsLoading(false);
      return;
    }
    if (response.data.result.length === 0) {
      setMessage("No se encontraron profesionales.");
      setIsLoading(false);
      return;
    }
    setDentists(response.data.result);
    setIsLoading(false);
  }

  const filteredDentists = dentists.filter(
    (dentist) =>
      dentist.professional_fullname
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      dentist.professional_email
        .toLowerCase()
        .includes(searchValue.toLowerCase())
  );

  function updateSelectedDentist(dentist) {
    setDentist(dentist);
    toggle();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Seleccionar Dentista</ModalHeader>
      <ModalBody className="pt-1">
        <Input
          type="text"
          placeholder="Buscar Dentista..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <br />
        <div
          style={{
            maxHeight: "30rem",
            overflowY: "auto",
          }}
        >
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th>Profesional</th>
                <th>Area</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <p>Cargando...</p>
              ) : dentists.length === 0 ? (
                <p>{message}</p>
              ) : (
                filteredDentists.map((dentist, index) => (
                  <tr key={index}>
                    <td>{dentist.professional_fullname.toUpperCase()}</td>
                    <td>
                      {dentist.professional_medical_area_name.toUpperCase()}
                    </td>
                    <td>{dentist.professional_email}</td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() => updateSelectedDentist(dentist)}
                      >
                        Seleccionar
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
}
