import Dashboard from "views/pages/dashboards/Dashboard.js";
import ListPatientAdmission from "views/pages/admission/list-patient-admission";
import ConsultationAppointment from "views/pages/consultation-appointment/consultation-appointment";
//import ReferralAppointment from "views/pages/referral/referral-appointment"
import Referral from "views/pages/referral/referral"
import Appointment from "views/pages/appointment/appointment"
import SynchronousSessions from "views/pages/synchronous-communication/SynchronousSessions";

const routes = [
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fa fa-columns text-primary",
    miniName: "A",
    component: Dashboard,
    layout: "/enroller",
  },
  {
    path: "/ingreso-paciente-recepcion",
    name: "Pre Ingreso",
    icon: "fa fa-address-book text-primary",
    miniName: "PI",
    component: ListPatientAdmission,
    layout: "/enroller",
  },
  {
    path: "/agendar-consulta",
    name: "Agendar Consulta",
    icon: "fa fa-address-book text-primary",
    miniName: "AC",
    component: Appointment,
    layout: "/enroller",
  },
  {
    path: "/interconsultas",
    name: "Interconsultas",
    icon: "fa fa-address-book text-primary",
    miniName: "IC",
    component: Referral,
    layout: "/enroller",
  },
  {
    path: "/sesiones-sincronicas",
    name: "Sesiones Sincrónicas",
    miniName: "SS",
    icon: "ni fas fa-video text-primary",
    component: SynchronousSessions,
    layout: "/enroller",
  },

  // {
  //   path: "/agendar-interconsulta",
  //   name: "Agendar Interconsulta",
  //   icon: "fa fa-address-book text-primary",
  //   miniName: "AI",
  //   component: ReferralAppointment,
  //   layout: "/enroller"
  // }
];

export default routes;
