import React, { useState, useEffect, useRef } from 'react';
import {
    InputGroup,
    Progress,
    CardHeader,
    InputGroupAddon,
    InputGroupText,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    ModalHeader,
    Label
} from 'reactstrap';
import SimpleModal from "../components/SimpleModal";
import axios from '../../../helpers/axiosConfig';
import { getList } from 'helpers/ListHelper';
import useList from 'hooks/useList';
import moment from 'moment';
import toast from 'react-hot-toast';
import CreateControlModal from './controls/CreateControlModal';
import EditControlModal from './controls/EditControlModal';


const GeneralAnamnesis = ({ location }) => {

    //Variables para control

    const [professionals, setProfessionals] = useState([]);
    const fetchProfessionals = async () => {
        const res = await getList("user/user-dentist");
        setProfessionals(res);
    };

    const [controls, setControls] = useState([]);
    const fetchControls = async (anamnesis_id) => {
        try {
            const res = await axios.get('general-anamnesis/control/get/' + anamnesis_id);
            setControls(res.data.result);

        } catch (error) {
            console.error("Error al traer controles:", error);
        }
    }

    // Estados para control de edición
    const [editModal, setEditModal] = useState(false);
    const [editingControl, setEditingControl] = useState(null);

    // Función para abrir el modal de edición
    const toggleEditModal = (control) => {
        setEditingControl(control); // Establece el control que se va a editar
        setEditModal(!editModal); // Abre el modal
    };

    const [controlDate, setControlDate] = useState('');
    const [assessment, setAssessment] = useState('');
    const [description, setDescription] = useState('');
    const [treatmentPerformed, setTreatmentPerformed] = useState('');
    const [secondaryDressingUsed, setSecondaryDressingUsed] = useState('');
    const [passiveDressingUsed, setPassiveDressingUsed] = useState('');
    const [assignedProfessional, setAssignedProfessional] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            assignedProfessional,
            controlDate,
            assessment,
            description,
            treatmentPerformed,
            secondaryDressingUsed,
            passiveDressingUsed,
            anamnesis_id: general.general_anamnesis_id
        };

        try {
            // Utiliza Axios para enviar una petición POST al endpoint deseado
            const response = await axios.post('general-anamnesis/control/crear', formData);
            // Manéjalo según corresponda
            console.log(response.data);

            toggleModal();

            setAssignedProfessional('');
            setControlDate('');
            setAssessment('');
            setDescription('');
            setTreatmentPerformed('');
            setSecondaryDressingUsed('');
            setPassiveDressingUsed('');

            fetchControls(general.general_anamnesis_id);
        } catch (error) {
            console.error("Hubo un error al enviar el formulario del control:", error);
        }
    };

    const [modal, setModalControl] = useState(false);

    const toggleModal = () => {
        setModalControl(!modal);
        fetchProfessionals();
    };

    const modalRef = useRef()
    const setModal = (options) => {
        modalRef.current.setModal(options);
    };
    //-|File|-----------------------------------------------------------//
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState({});
    const [hasFile, setHasFile] = useState(false);
    const [files, setFiles] = useState([]);
    const extAllowed = "jpeg jpg png pdf";
    const sizAllowed = 120000000;
    const defaultHelpText =
        extAllowed + " (max: " + sizAllowed / 1000000 + " MB)";
    const [helpText, setHelpText] = useState(defaultHelpText);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);

    const handleHasFile = () => {
        setHasFile(!hasFile);
        cleanFile();
        setHelpText(defaultHelpText);
    };

    const fetchFiles = async () => {
        try {
            const res = await axios.post("photo/anamnesis_general/obtain", {
                anamnId: general.general_anamnesis_id,
            });
            if (!res.data.error) {
                setFiles(res.data.result);
                setModal({});
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const loadFile = (file) => {

        if (file[0]?.name) {
            const size = file[0].size;
            const full = file[0]?.name;
            const type = full
                .slice(((full.lastIndexOf(".") - 1) >>> 0) + 2)
                .toLowerCase();
            if (extAllowed.indexOf(type) >= 0) {
                if (size <= sizAllowed) {
                    setFileName(full.slice(0, full.lastIndexOf(".")));
                    setFileType(type);
                    setFile(file);
                    setHelpText(full);
                    return;
                } else {
                    cleanFile();
                    setHelpText("Tamaño de archivo invalido");
                    return;
                }
            }
            cleanFile();
            setHelpText("Formato de archivo invalido");
        }

    };

    const cleanFile = () => {
        setFileName("");
        setFileType("");
        setFile({});
    };

    const uploadFile = () => {
        if (fileName.length > 60) {
            setHelpText(
                "El archivo excede el maximo de caracteres permitidos. (max:60)"
            );
        } else if (fileName) {
            setShowProgress(true);
            const f = new FormData();
            f.append("file", file[0], fileName + "." + fileType);
            const config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentCompleted);
                },
            };

            axios
                .post("file/img_general/" + location.state.run, f, config)
                .then((response) => {
                    saveFile();
                    setUploadProgress(0);
                    setShowProgress(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const saveFile = async () => {
        const res = await axios.post("photo/anamnesis_general", {
            anamnId: general.general_anamnesis_id,
            nombreImagen: fileName + "." + fileType,
            ruta: "static/images_anam_general/" + location.state.run,
        });
        if (!res.data.error) {
            fetchFiles();
            cleanFile();
            setHelpText(defaultHelpText);
            setHasFile(false);
        }

    };

    const removeFile = async (fId, name) => {
        const res = await axios.post("photo/anamnesis_general/rm/", {
            fId: fId,
            route: location.state.run + "/" + name,
        });
        if (!res.data.error) {
            fetchFiles();
        }

    };

    const [has, setHas] = useState(false);
    //LOCATION###############################################################
    useEffect(() => {
        if (!location?.state?.id) {
            //location.state.id = 11;
            fetchLastGeneral(0);
        } else {
            fetchLastGeneral(location.state.id);
        };
        //eslint-disable-next-line    
    }, []);

    //GENERAL###############################################################
    const defaultGeneral = {
        general_anamnesis_id: 0,
        person_id: 0,
        allergies: "",
        other_disease: "",
        under_treatment: 0,
        previous_surgeries: "",
        medicine_dose: "",
        medicine_allergies: "",
        radio_chemo_therapy: "",
        consult_motive: "",
        consult_motive_history: "",
        medic_alert: "",
        ohip14sp_last_result: 0,
        ohipedent_last_result: 0,
        chronic_medications: "",
        physical_exercise: 0,
        history: "",
        injury_classification: "",
        evaluation_time: 0,
        eva_scaletory: 0,
        tabacco: false,
        tabacco_quantity: 0,
        charing_cross_result: 0,
        treatment: "",
    };

    const addSmoke = () => {
        setGeneral(prevGeneral => ({
            ...prevGeneral,
            tabacco: !prevGeneral.tabacco,
            tabacco_quantity: !prevGeneral.tabacco ? 0 : prevGeneral.tabacco_quantity
        }));
    };

    const setSmokeQuantity = (quantity) => {
        setGeneral(prevGeneral => ({
            ...prevGeneral,
            tabacco_quantity: quantity
        }));
    };

    const [general, setGeneral] = useState(defaultGeneral);
    const fetchLastGeneral = async (person_id) => {
        const res = await getList("general-anamnesis/" + person_id);
        if (res.length === 1) {
            setGeneral(res[0]);
            fetchDiseases(res[0].general_anamnesis_id);
            setHas(true);
            fetchControls(res[0].general_anamnesis_id);
        };
    };

    const handleInputChange = e => {
        setGeneral({
            ...general,
            [e.target.name]: e.target.value
        });
    };

    const handleInputChangeControl = (e) => {
        const { name, value } = e.target;
        setEditingControl(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleEditControl = async () => {
        const payload = {
            assessment: editingControl.assessment,
            description: editingControl.description,
            treatment_performed: editingControl.treatment_performed,
            secondary_dressing_used: editingControl.secondary_dressing_used,
            passive_dressing_used: editingControl.passive_dressing_used,
            control_id: editingControl.general_anamnesis_control_id
        };

        try {
            const response = await axios.put('general-anamnesis/control/update', payload);
            setEditModal(false);
            fetchControls(general.general_anamnesis_id);
        } catch (error) {
            console.log(payload)
        }
    };

    const handleInputCheckedChange = e => {
        setGeneral({
            ...general,
            [e.target.name]: e.target.checked
        });
    };

    const handleCurrentDiseases = e => {
        setDiseases({
            ...diseases,
            [e.target.name]: e.target.checked
        });
    };

    //DISEASES##############################################################
    const prmDiseases = useList("list/diseases");
    const [diseases, setDiseases] = useState([]);
    const [diseasesSelected, setDiseasesSelected] = useState([]);

    useEffect(() => {
        const filteredPrmDiseases = prmDiseases.filter(prm => diseases[prm.disease_id] === 1);
        setDiseasesSelected(filteredPrmDiseases);
    }, [diseases, prmDiseases]);


    const fetchDiseases = async (general_anamnesis_id) => {
        const obj = {};
        const res = await getList("general-anamnesis/crud/" + general_anamnesis_id);
        for (let i = 0; i < res.length; i++) {
            obj[res[i]['disease_id']] = res[i]['has_disease'];
        }
        setDiseases(obj);
    };

    const setStatusPerson = async (idPersona) => {

        const resp = await axios.get("person/consult-status-person/" + idPersona);
        if (!resp.data.error) {
            if (resp.data.result[0]["status_id"] == 3) {
                const res = await axios.put('person/set-status-person/',
                    {
                        status_id: 6,
                        personId: idPersona
                    })

                if (!res.data.error) {
                    console.log('Status actualizado');
                };
            } else {
                console.log('No es posible actualizar el estado');
            }
        }

    };

    //CRUD##################################################################
    const saveChanges = async (e) => {
        e.preventDefault();
        const d = [];
        for (var i in diseases) {
            if (diseases[i]) {
                d.push(i);
            };
        };
        const toastId = toast.loading('Guardando...', { removeDelay: 0 });
        try {
            const res = await axios.post("general-anamnesis/crud", { ...general, d });
            setStatusPerson(location.state.id);
            toast.remove(toastId);
            if (!res.data.error) {
                toast.success("¡Datos guardados correctamente!", {
                    id: 'success'
                })
            } else {
                toast.error("¡Error al guardar los datos!")
                console.log('Err: ' + res.data.result.code + ' ' + res.data.result.sqlMessage);
            };
        } catch (e) {
            toast.remove(toastId);
            toast.error("¡Error al guardar los datos!")
            console.log('Err: ' + e);
        };
    };

    //GROUP#################################################################
    //const [group, setGroup] = useState('motive');
    const [group, setGroup] = useState(location.state.activeGroup || 'motive');


    const handleGroupChange = (newGroup) => {
        setGroup(newGroup);
        fetchFiles();
    };


    //JSX##################################################################
    return (
        <Container>
            <SimpleModal ref={modalRef} />
            <h1>Anamnesis general</h1>
            {!has
                ? <Card><h4>El paciente no tiene registros en Anamnesis general.</h4></Card>
                : <Card>
                    <CardBody className="pb-0 pt-1">
                        <ModalHeader className="pb-5 pl-0">
                            {/* eslint-disable-next-line */}
                            <a style={{ paddingRight: "15px", cursor: "pointer", ...(group === 'motive' ? { color: "#11cdef" } : {}) }} onClick={() => setGroup('motive')}>Motivo de consulta</a>
                            {/* eslint-disable-next-line */}
                            <a style={{ paddingRight: "15px", cursor: "pointer", ...(group === 'background' ? { color: "#11cdef" } : {}) }} onClick={() => setGroup('background')}>Antecedentes mórbidos personales</a>

                            <a
                                style={{ paddingRight: "15px", cursor: "pointer", ...(group === 'medicina_regenerativa' ? { color: "#11cdef" } : {}) }}
                                onClick={() => handleGroupChange('medicina_regenerativa')}
                            >
                                Medicina regenerativa
                            </a>

                            <a style={{ paddingRight: "15px", cursor: "pointer", ...(group === 'controls' ? { color: "#11cdef" } : {}) }} onClick={() => setGroup('controls')}>Control MR</a>

                        </ModalHeader>
                        <Form id="id-form-update" onSubmit={(e) => saveChanges(e)} role="form">
                            {group === 'motive' && <>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Motivo Consulta</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="consult_motive"
                                            value={general.consult_motive}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Historial del motivo de Consulta</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="consult_motive_history"
                                            value={general.consult_motive_history}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Alerta médica especial y comentarios relevantes sobre el paciente</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="medic_alert"
                                            value={general.medic_alert}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col className="d-flex">
                                        <Label className="form-control-label">Está bajo tratamiento</Label>
                                        <label className="custom-toggle custom-toggle-warning ml-3">
                                            <Input
                                                type="checkbox"
                                                name="under_treatment"
                                                checked={general.under_treatment}
                                                onChange={e => { handleInputCheckedChange(e) }}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí" />
                                        </label>
                                    </Col>
                                </FormGroup>
                                {general.under_treatment && <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Detalle del tratamiento</Label>
                                        <Input
                                            autoComplete="off" maxLength="1000"
                                            type="textarea"
                                            name="treatment"
                                            value={general.treatment}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>}
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Alergias</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="allergies"
                                            value={general.allergies}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Cirugías previas/ Hospitalización/</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="previous_surgeries"
                                            value={general.previous_surgeries}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Fármacos y Posología</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="medicine_dose"
                                            value={general.medicine_dose}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Alergia a Fármacos (especificar cuál)</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="medicine_allergies"
                                            value={general.medicine_allergies}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Radioterapia / Quimioterapia</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="radio_chemo_therapy"
                                            value={general.radio_chemo_therapy}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col md="6">
                                        <Label className="form-control-label" htmlFor="example-text-input">
                                            Encuesta OHIP-14Sp (
                                            <a href="https://forms.gle/7WAjk2fmTzgxRU6S8" target="_blank">ver</a>)
                                        </Label>
                                        <Input
                                            max="9999" min="-9999"
                                            value={general.ohip14sp_last_result || ""}
                                            name="ohip14sp_last_result"
                                            type="number"
                                            onChange={e => { handleInputChange(e) }}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Label className="form-control-label" htmlFor="example-text-input">
                                            Encuesta OHIP-EDENT (
                                            <a href="https://forms.gle/CeFH9tWVQ4dqkuR16" target="_blank">ver</a>)
                                        </Label>
                                        <Input
                                            max="9999" min="-9999"
                                            value={general.ohipedent_last_result || ""}
                                            name="ohipedent_last_result"
                                            type="number"
                                            onChange={e => { handleInputChange(e) }}
                                        />
                                    </Col>
                                </FormGroup>
                            </>}
                            {group === 'background' && <>
                                <FormGroup className="row">
                                    <Col md="10">
                                        {prmDiseases.map(prm => (
                                            <div key={prm.disease_id} className="custom-control custom-checkbox custom-checkbox-primary">
                                                <Input
                                                    type="checkbox"
                                                    id={prm.disease_id}
                                                    name={prm.disease_id}
                                                    className="custom-control-input"
                                                    checked={diseases[prm.disease_id]}
                                                    onChange={e => handleCurrentDiseases(e)}
                                                />
                                                <label className="custom-control-label" htmlFor={prm.disease_id}>{prm.name}</label>
                                            </div>
                                        ))}
                                    </Col>

                                </FormGroup>

                                <FormGroup className="row">
                                    <Col>
                                        <Label className="form-control-label">Otros / Observaciones</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="other_disease"
                                            value={general.other_disease}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                            </>}

                            {group === 'medicina_regenerativa' && <>

                                <FormGroup className="row ml-0">
                                    <h4>Antecedentes mórbidos previamente declarados</h4>
                                    <Col md="10">
                                        {diseasesSelected.map(prm => (
                                            <div key={prm.disease_id} className="custom-control custom-checkbox custom-checkbox-primary">
                                                <Input
                                                    type="checkbox"
                                                    id={prm.disease_id}
                                                    name={prm.disease_id}
                                                    className="custom-control-input"
                                                    checked={diseases[prm.disease_id]}

                                                />
                                                <label className="custom-control-label" htmlFor={prm.disease_id}>{prm.name}</label>
                                            </div>
                                        ))}
                                    </Col>

                                    <Col className="mt-3" md="12">
                                        <Label className="form-control-label">Observaciones</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="other_disease"
                                            value={general.other_disease}
                                            disabled
                                        />
                                    </Col>

                                    <Col className="mt-2" md="12">
                                        <Label className="form-control-label">Fármacos de uso crónico</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="text"
                                            name="chronic_medications"
                                            value={general.chronic_medications}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>

                                    <Col className="mt-3 d-flex ">
                                        <Label className="form-control-label">Ejercicio Físico</Label>
                                        <label className="custom-toggle custom-toggle-warning ml-3">
                                            <Input
                                                type="checkbox"
                                                name="physical_exercise"
                                                checked={general.physical_exercise}
                                                onChange={e => { handleInputCheckedChange(e) }}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí" />
                                        </label>
                                    </Col>
                                    {/* </FormGroup>
                                <FormGroup className="row ml-0"> */}
                                    <Col className="mt-3 d-flex">
                                        <Label className="form-control-label">¿Fuma?</Label>
                                        <label className="custom-toggle custom-toggle-warning ml-3">
                                            <Input
                                                type="checkbox"
                                                name="tabacco"
                                                checked={general.tabacco}
                                                onChange={addSmoke}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí" />
                                        </label>
                                    </Col>

                                    {general.tabacco &&
                                        <Col className="mt-3" md="12">
                                            <Label className="form-control-label">Cantidad de cigarros al día: </Label>
                                            <Input
                                                autoComplete="off"
                                                type="number"
                                                step="1"
                                                name="tabacco_quantity"
                                                value={general.tabacco_quantity}
                                                onChange={(e) => setSmokeQuantity(e.target.value)}
                                            />
                                        </Col>
                                    }
                                </FormGroup>
                                <FormGroup className="row ml-0">
                                    <h4>Descripción de la lesión</h4>
                                    <Col md="12">
                                        <Label className="form-control-label">Historial</Label>
                                        <Input
                                            autoComplete="off" maxLength="250"
                                            type="textarea"
                                            name="history"
                                            value={general.history}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>

                                    <Col className="mt-2" md="6">
                                        <Label className="form-control-label" for="injury" >
                                            Clasificación
                                        </Label>
                                        <Input
                                            id="injury"
                                            name="injury_classification"
                                            type="select"
                                            value={general.injury_classification}
                                            onChange={e => handleInputChange(e)}
                                        >
                                            <option value="">Seleccione una opción...</option>
                                            <option value="Herida">Herida</option>
                                            <option value="Úlcera">Úlcera</option>
                                            <option value="Quemadura">Quemadura</option>
                                            <option value="Pie diabético">Pie diabético</option>
                                        </Input>
                                    </Col>

                                    <Col className="mt-2" md="6">
                                        <Label className="form-control-label" id="evaluation">Tiempo de evolución</Label>
                                        <InputGroup>
                                            <Input
                                                id="evaluation"
                                                type="number"
                                                min={0}
                                                name="evaluation_time"
                                                value={general.evaluation_time}
                                                onChange={(e) => { handleInputChange(e) }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>Semanas</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>

                                    <Col className="mt-2" md="12" >
                                        <Label className="form-control-label">Escala EVA</Label>
                                        <Input
                                            min={0}
                                            max={10}
                                            type="number"
                                            name="eva_scaletory"
                                            value={general.eva_scaletory}
                                            onChange={e => handleInputChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col md="6">
                                        <Label className="form-control-label mb-3">
                                            Archivos disponibles
                                        </Label>
                                        {files.map((file) => (
                                            <div key={file.foto_id} className="list-group mb-2">
                                                <InputGroup>
                                                    <Button
                                                        className="mr-2"
                                                        color="danger"
                                                        size="sm"
                                                        outline
                                                        onClick={() =>
                                                            setModal({
                                                                title: "Confirmación",
                                                                text:
                                                                    "Desea eliminar " + file.foto_nombre + "?",
                                                                type: 2,
                                                                fx: () =>
                                                                    removeFile(file.foto_id, file.foto_nombre),
                                                            })
                                                        }
                                                    >
                                                        <i className="far fa-trash-alt" />
                                                    </Button>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href={
                                                            process.env.REACT_APP_API_URL +
                                                            `${file.url}` +
                                                            "/" +
                                                            `${file.foto_nombre}`
                                                        }
                                                    >
                                                        {file.foto_nombre}
                                                    </a>
                                                </InputGroup>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <CardHeader>
                                                <FormGroup className="row">
                                                    <Col className="d-flex">
                                                        <Label className="form-control-label">
                                                            ¿Subir una nuevo archivo?
                                                        </Label>
                                                        <label className="custom-toggle custom-toggle-info ml-3">
                                                            <Input
                                                                type="checkbox"
                                                                checked={hasFile}
                                                                onChange={() => handleHasFile()}
                                                            />
                                                            <span
                                                                className="custom-toggle-slider rounded-circle"
                                                                data-label-off="No"
                                                                data-label-on="Sí"
                                                            />
                                                        </label>
                                                    </Col>
                                                </FormGroup>
                                            </CardHeader>
                                            {hasFile && (
                                                <CardBody>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    onClick={() => uploadFile()}
                                                                >
                                                                    Agregar
                                                                </Button>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="off"
                                                                type="text"
                                                                name="treatment_plan"
                                                                value={fileName}
                                                                onChange={(e) => setFileName(e.target.value)}
                                                                disabled
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        document.getElementById("files").click();
                                                                    }}
                                                                >
                                                                    Buscar
                                                                </Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                        <h5
                                                            align="right"
                                                            style={{
                                                                marginRight: "10px",
                                                                marginBottom: "0px",
                                                            }}
                                                        >
                                                            {helpText}
                                                        </h5>
                                                        {showProgress && (
                                                            <Progress
                                                                value={uploadProgress}
                                                                animated
                                                                color="success"
                                                            >
                                                                {uploadProgress}%
                                                            </Progress>
                                                        )}
                                                        <input
                                                            type="file"
                                                            accept="image/*, .pdf"
                                                            id="files"
                                                            style={{ visibility: "hidden" }}
                                                            onChange={(e) => loadFile(e.target.files)}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            )}
                                        </Card>
                                    </Col>
                                </FormGroup>
                            </>}
                            {group === 'controls' && <>
                                <FormGroup className="row ml-0">
                                    <Button color="primary" onClick={toggleModal}>
                                        + Agregar control
                                    </Button>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Col md="6">
                                        <Label className="form-control-label" htmlFor="exampleFormControlSelect3">
                                            Última encuesta CHARING CROSS (
                                            <a href="https://forms.gle/KaonbaS7wETopBoK9" target="_blank">ver</a>)
                                        </Label>
                                        <Input
                                            max="9999" min="-9999"
                                            value={general.charing_cross_result}
                                            name="charing_cross_result"
                                            type="number"
                                            onChange={e => { handleInputChange(e) }}
                                        />
                                    </Col>
                                </FormGroup>

                                <div>
                                    {controls.map((control, index) => (
                                        <Card key={index} className="row">
                                            <CardBody>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Label for="controlDate" className="mb-0 mr-3">
                                                        Control - Fecha: {moment(control.control_date).format('YYYY-MM-DD')}
                                                    </Label>
                                                    <Button onClick={() => toggleEditModal(control)} title="Editar" className="mr-2" color="primary" size="sm" outline>
                                                        <i className="far fa-edit" />
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))}

                                    <EditControlModal
                                        editModal={editModal}
                                        setEditModal={setEditModal}
                                        editingControl={editingControl}
                                        handleInputChangeControl={handleInputChangeControl}
                                        handleEditControl={handleEditControl}
                                        run={location.state.run}
                                    />
                                </div>

                                <CreateControlModal
                                    modal={modal}
                                    toggleModal={toggleModal}
                                    handleSubmit={handleSubmit}
                                    professionals={professionals}
                                    assignedProfessional={assignedProfessional}
                                    setAssignedProfessional={setAssignedProfessional}
                                    setControlDate={setControlDate}
                                    setAssessment={setAssessment}
                                    setDescription={setDescription}
                                    setTreatmentPerformed={setTreatmentPerformed}
                                    setSecondaryDressingUsed={setSecondaryDressingUsed}
                                    setPassiveDressingUsed={setPassiveDressingUsed}
                                />
                            </>}
                            <br />
                            <div className="float-right mb-4 mr-2">
                                <Button type="submit" id="id-btn-save" color="primary">Guardar</Button>{' '}
                                {/* <Button onClick={() => {setDisplayModal(!displayModal)}} color="secondary">Cancelar</Button> */}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            }
        </Container>
    );
};

export default GeneralAnamnesis;
