import React, { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { leftParticipant } from "./api/SecretCode";
import galleta from "js-cookie";

export default function Conference() {
  const [roomConfig, setRoomConfig] = useState(null);

  useEffect(() => {
    const storedRoom = localStorage.getItem("videoconferenceSettings");
    if (!storedRoom) {
      window.close();
    } else {
      setRoomConfig(JSON.parse(storedRoom));
    }

    // Ocultar elementos "nav"
    const navElements = document.querySelectorAll("nav");
    if (navElements) {
      navElements.forEach((element) => {
        element.style.display = "none";
      });
    }

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
  }, []);

  const closeWindows = async () => {
    let userInfo = galleta.get("user");

    if (typeof userInfo === "string") {
      userInfo = JSON.parse(userInfo);
    }
    await leftParticipant(
      userInfo.user.token,
      roomConfig.name,
      userInfo.user.result[0].user_id
    );
    window.close();
  };

  if (!roomConfig) {
    return <p>Loading...</p>;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        margin: "0",
        padding: "0",
        overflow: "hidden", // Ocultar scrolls
        backgroundColor: "black",
      }}
    >
      <JitsiMeeting
        domain="jitsimeet.iie.cl"
        roomName={roomConfig.name}
        jwt={roomConfig.token}
        configOverwrite={{
          enableWelcomePage: false,
          videoQuality: {
            codecPreferenceOrder: ["AV1", "VP9", "VP8"],
          },
          disableModeratorIndicator: true,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        userInfo={{
          displayName: roomConfig.username ?? "Usuario",
        }}
        onApiReady={(externalApi) => {
          externalApi.addListener("videoConferenceLeft", closeWindows);
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.position = "absolute";
          iframeRef.style.top = "0";
          iframeRef.style.left = "0";
          iframeRef.style.height = "100%";
          iframeRef.style.width = "100%";
          iframeRef.style.border = "none";
        }}
      />
    </div>
  );
}
