import Axios from "helpers/axiosConfig";
import AuthHelper from "helpers/AuthHelper";

/**
 * @typedef {Object} Session
 * @property {number} id - Identificador único de la sesión.
 * @property {number} patient_id - Identificador único del paciente.
 * @property {string} patient_names - Nombres del paciente.
 * @property {string} patient_lastnames - Apellidos del paciente.
 * @property {string} patient_run - RUN del paciente.
 * @property {number} dentist_id - Identificador único del dentista.
 * @property {string} dentist_names - Nombres del dentista.
 * @property {string} dentist_lastnames - Apellidos del dentista.
 * @property {number} specialist_id - Identificador único del especialista.
 * @property {string} specialist_names - Nombres del especialista.
 * @property {string} specialist_lastnames - Apellidos del especialista.
 * @property {number} medical_area_id - Identificador único del área medica.
 * @property {string} medical_area_name - Nombre del área medica.
 * @property {string} description - Descripción de la sesión.
 * @property {string} created_at - Fecha y hora de creación de la sesión.
 * @property {string} updated_at - Fecha y hora de actualización de la sesión.
 * @property {number} status_id - Identificador del estado de la sesión.
 * @property {string} status_name - Nombre del estado de la sesión.
 * @property {string} starts_at - Fecha de inicio de la sesión, nulo si la sesión está pendiente de asignar.
 * @property {string} ends_at - Fecha de fin de la sesión, nulo si la sesión está pendiente de asignar.
 *
 * Obtiene todas las sesiones sincronicas.
 *
 * @returns {Promise<Session[]>} - Una promesa que resuelve con un array de sesiones sincronicas.
 */
export async function getAllSynchronousSessions({
  userId = AuthHelper.getUserId(),
}) {
  const userRole = await AuthHelper.getRole();
  if (userRole === 5 || userRole === 3) {
    return await Axios.get("/synchronous-sessions");
  }
  const sessions = await Axios.get(
    "/synchronous-sessions/professional/" + userId
  );
  return sessions;
}

/**
 * Crea una nueva sesion sincronica
 *
 * @param {{professional_id: int, specialist_id: int, patient_id: int, description: string, medical_area_id: int}} session
 * @returns {response: Promise}
 */
export async function newSynchronousSession(session) {
  const response = await Axios.post("/synchronous-sessions", session);
  return response;
}

export async function updateSynchronousSession(session) {
  const response = await Axios.put("/synchronous-sessions", session);
  return response;
}

export async function getSynchronousEvents({ professionalId }) {
  const events = Axios.get("/synchronous-sessions/events/" + professionalId);
  console.log(events);
  return events;
}