import Axios from "helpers/axiosConfig";

function setEventsClassName(events, className) {
  return events.map((event) => {
    return {
      ...event,
      className: className,
    };
  });
}

export async function getCalendarEvents({ professionalId }) {
  try {
    const generalEvents = await Axios.get("/agenda/" + professionalId).then(
      (res) => {
        return setEventsClassName(res.data.result, "bg-info");
      }
    );
    const synchronousEvents = await Axios.get(
      "/synchronous-sessions/events/" + professionalId
    ).then((res) => {
      return setEventsClassName(res.data, "bg-warning text-white border-warning");
    });

    const events = [...generalEvents, ...synchronousEvents];
    return events;
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    return [];
  }
}
