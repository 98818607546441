import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthHelper from "../../../helpers/AuthHelper";
import { formatRut } from "@fdograph/rut-utilities";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";


export default function Login() {

  const [rut, setRut] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    AuthHelper.login(formatRut(rut), password).then((res) => {
      if (res.auth) {
        if (location.state?.referrer) {
          window.location.href = location.state.referrer;
        } else {
          window.location.href = "/agenda";
        }
      } else {
        toast.error("Credenciales incorrectas");
      }
    });
  };
  

  useEffect(() => {
    if (location?.state?.errorMessage) {
      toast.error(location.state.errorMessage);
    }
  } ,[location]);
  
  return (
    <>
      <AuthHeader title="Bienvenido a Tego" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-muted text-center mt-2 mb-3">
                  Por favor, ingrese con su Rut o DNI:
                </div>
                <Form onSubmit={handleLogin} role="form" method="post">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Rut o DNI"
                        type="run"
                        onChange={(e) => {
                          setRut(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Ingresar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Toaster position="top-right" reverseOrder={false} />
      </Container>
    </>
  );
}