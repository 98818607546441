import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Card,
  CardBody,
  Table,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/loading.css";
import { getList } from "helpers/ListHelper";
import AuthHelper from "helpers/AuthHelper";
import axios from "../../../helpers/axiosConfig";

const ListRecords = () => {
  const [persons, setPersons] = useState([]);
  const [namesOrRunSearchTerm, setNamesOrRunSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [townSearchTerm, setTownSearchTerm] = useState("");
  const [stateSearchTerm, setStateSearchTerm] = useState("");
  const [registeredTowns, setRegisteredTowns] = useState([]);
  const [registeredStates, setRegisteredStates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [current_person, setCurrentPerson] = useState({});
  const [activationSearchTerm, setActivationSearchTerm] = useState("");
  const [registeredActivations, setRegisteredActivations] = useState([]);
  const jsonObjects = {
    1: "Activado",
    0: "Desactivado",
  };

  const fetchPersons = useCallback(async () => {
    const usr = await getList("person/typeuser/" + AuthHelper.getUserId());
    const professionalType = usr[0].name;

    let endpoint = "person/listpatientbyprofessional/" + AuthHelper.getUserId();

    if (professionalType === "Especialista") {
      endpoint = "person/listpatientbyspecialist/" + AuthHelper.getUserId();
    }

    if (professionalType == "Administrador") {
      endpoint = "person/listPatient";
    }
    const res = await getList(endpoint);
    setLoading(false);
    setPersons(res);
    fetchRegisteredTownsAndStates(res);
    if (professionalType == "Administrador") {
      fetchActivationsStates(res);
    }
  });

  const fetchActivationsStates = (persons) => {
    const registeredActivations = [];
    persons.forEach((person) => {
      if (!registeredActivations.includes(jsonObjects[person.active])) {
        if (person.active === 1) {
          registeredActivations.push("Activado");
        } else {
          registeredActivations.push("Desactivado");
        }
      }
    });
    setRegisteredActivations(registeredActivations);
  };

  const fetchRegisteredTownsAndStates = (persons) => {
    const registeredTowns = [];
    const registeredStates = [];
    persons.forEach((person) => {
      if (!registeredTowns.includes(person.town_name)) {
        registeredTowns.push(person.town_name);
      }
      if (!registeredStates.includes(person.status_description)) {
        registeredStates.push(person.status_description);
      }
    });
    setRegisteredStates(registeredStates);
    setRegisteredTowns(registeredTowns);
  };

  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  const openModal = (e_person) => {
    setCurrentPerson(e_person);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const desactivateUser = async () => {
    await axios.put("person/" + current_person.run);
    await fetchPersons();
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={modalOpen}
        backdrop={false}
        centered
        fade={false}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirmación
        </ModalHeader>
        <ModalBody className="pt-1">
          <Card style={{ boxShadow: "0px 0px 0px" }}>
            <h4>
              {current_person.active === 1
                ? "¿Estás seguro de que deseas desactivar este usuario?"
                : "¿Estás seguro de que deseas activar este usuario?"}
            </h4>
            <br />
            {current_person.active === 1 ? (
              <Button color="danger" onClick={desactivateUser}>
                Sí, desactivar
              </Button>
            ) : (
              <Button color="primary" onClick={desactivateUser}>
                Sí, activar
              </Button>
            )}
            <br />
            <Button color="secondary" onClick={closeModal}>
              No, cancelar
            </Button>
          </Card>
        </ModalBody>
      </Modal>
      <Container className="mt-3">
        <Card>
          <CardBody>
            <h1>Fichas Clinicas</h1>
            <Row>
              <Col md={AuthHelper.getRole() === 3 ? "3" : "6"}>
                <Input
                  type="text"
                  placeholder="Buscar Paciente..."
                  autoComplete="off"
                  name="look_for_namesOrRun"
                  value={namesOrRunSearchTerm}
                  onChange={(e) => setNamesOrRunSearchTerm(e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  type="select"
                  autoComplete="off"
                  name="look_for_town"
                  value={townSearchTerm}
                  onChange={(e) => setTownSearchTerm(e.target.value)}
                >
                  <option value="Todas las comunas">Todas las comunas</option>
                  {registeredTowns.map((town) => (
                    <option value={town}>{town}</option>
                  ))}
                </Input>
              </Col>
              <Col md="3">
                <Input
                  type="select"
                  autoComplete="off"
                  name="look_for_state"
                  value={stateSearchTerm}
                  onChange={(e) => setStateSearchTerm(e.target.value)}
                >
                  <option value="Todos los estados">Todos los estados</option>
                  {registeredStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Input>
              </Col>
              {AuthHelper.getRole() === 3 ? (
                <Col md="3">
                  <Input
                    type="select"
                    autoComplete="off"
                    name="look_for_activation"
                    value={activationSearchTerm}
                    onChange={(e) => setActivationSearchTerm(e.target.value)}
                  >
                    <option value="Activado y desactivado">
                      Activado y desactivado
                    </option>
                    {registeredActivations.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
                  </Input>
                </Col>
              ) : null}
            </Row>
            <br />
            {loading ? (
              <div className="w-100 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr className="text-center">
                    <th className="px-2" scope="col">
                      Nombre paciente
                    </th>
                    <th className="px-2" scope="col">
                      Run
                    </th>
                    <th className="px-2" scope="col">
                      Comuna
                    </th>
                    <th className="px-2" scope="col">
                      Estado
                    </th>
                    <th className="px-2" scope="col">
                      Ficha Clinica
                    </th>
                    {AuthHelper.getRole() === 3 ? (
                      <th className="px-2" scope="col">
                        Activado
                      </th>
                    ) : (
                      <th className="px-2" scope="col"></th>
                    )}
                  </tr>
                </thead>
                {/* {loading && <div className="loader align-self-center text-center justify-content-center"></div>} */}
                <tbody>
                  {AuthHelper.getRole() === 3
                    ? persons
                        .filter((val) => {
                          if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados")
                          ) {
                            if (
                              jsonObjects[val.active].toLowerCase() ==
                              activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas")
                          ) {
                            if (
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados")
                          ) {
                            if (
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (namesOrRunSearchTerm == "") {
                            if (
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                              val.run.includes(namesOrRunSearchTerm)
                            )
                              return val;
                          } else if (
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados")
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            townSearchTerm == "" ||
                            townSearchTerm == "Todas las comunas"
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados") &&
                            (activationSearchTerm == "" ||
                              activationSearchTerm == "Activado y desactivado")
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            stateSearchTerm == "" ||
                            stateSearchTerm == "Todos los estados"
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          } else if (
                            activationSearchTerm == "" ||
                            activationSearchTerm == "Activado y desactivado"
                          ) {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase())
                            )
                              return val;
                          } else {
                            if (
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) ||
                                val.run.includes(namesOrRunSearchTerm)) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase()) &&
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()) &&
                              jsonObjects[val.active].toLowerCase() ==
                                activationSearchTerm.toLowerCase()
                            )
                              return val;
                          }
                        })
                        .map((e_person) => (
                          <tr
                            className="text-center text-uppercase"
                            key={e_person.person_id}
                          >
                            <td
                              align="left"
                              className="px-2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "250px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e_person.names + " " + e_person.lastnames}
                            </td>
                            <td className="px-2">{e_person.run}</td>
                            <td className="px-2">{e_person.town_name}</td>
                            <td className="px-2">
                              {e_person.status_description}
                            </td>
                            <td>
                              {/* <Button onClick={() => showModalAppointments(e_person)}>Listar</Button> */}
                              <Link
                                to={{
                                  pathname: `/ficha-clinica/${e_person.person_id}`,
                                  state: {
                                    id: e_person.person_id,
                                    run: e_person.run,
                                    name: e_person.names,
                                    apellido: e_person.lastnames,
                                    fecha_nacimiento: e_person.birthdate,
                                  },
                                }}
                                style={{ color: "white" }}
                              >
                                <Button color="primary">Ver</Button>
                              </Link>
                            </td>
                            <td className="px-2">
                              <Button
                                variant="outlined"
                                onClick={() => openModal(e_person)}
                                color={
                                  e_person.active === 1 ? "primary" : "light"
                                }
                                className={
                                  e_person.active === 1
                                    ? "fas fa-user-check"
                                    : "fas fa-user-slash"
                                }
                              ></Button>
                            </td>
                          </tr>
                        ))
                    : persons
                        .filter((val) => {
                          if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados")
                          ) {
                            return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas")
                          ) {
                            if (
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            namesOrRunSearchTerm == "" &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todos los estados")
                          ) {
                            if (
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            (townSearchTerm == "" ||
                              townSearchTerm == "Todas las comunas") &&
                            (stateSearchTerm == "" ||
                              stateSearchTerm == "Todas los estados")
                          ) {
                            if (
                              val.run.includes(namesOrRunSearchTerm) ||
                              val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (namesOrRunSearchTerm == "") {
                            if (
                              val.status_description
                                .toLowerCase()
                                .includes(stateSearchTerm.toLowerCase()) &&
                              val.town_name
                                .toLowerCase()
                                .includes(townSearchTerm.toLowerCase())
                            )
                              return val;
                          } else if (
                            townSearchTerm == "" ||
                            townSearchTerm == "Todas las comunas"
                          ) {
                            if (
                              val.run.includes(namesOrRunSearchTerm) ||
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) &&
                                val.status_description
                                  .toLowerCase()
                                  .includes(stateSearchTerm.toLowerCase()))
                            )
                              return val;
                          } else if (
                            stateSearchTerm == "" ||
                            stateSearchTerm == "Todos los estados"
                          ) {
                            if (
                              val.run.includes(namesOrRunSearchTerm) ||
                              (val.fullname
                                .toLowerCase()
                                .includes(namesOrRunSearchTerm.toLowerCase()) &&
                                val.town_name
                                  .toLowerCase()
                                  .includes(townSearchTerm.toLowerCase()))
                            )
                              return val;
                          } else if (
                            (val.run.includes(namesOrRunSearchTerm) ||
                              val.fullname
                                .toLowerCase()
                                .includes(
                                  namesOrRunSearchTerm.toLowerCase()
                                )) &&
                            val.town_name
                              .toLowerCase()
                              .includes(townSearchTerm.toLowerCase()) &&
                            val.status_description
                              .toLowerCase()
                              .includes(stateSearchTerm.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((e_person) => (
                          <tr
                            className="text-center text-uppercase"
                            key={e_person.person_id}
                          >
                            <td
                              align="left"
                              className="px-2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "250px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e_person.names + " " + e_person.lastnames}
                            </td>
                            <td className="px-2">{e_person.run}</td>
                            <td className="px-2">{e_person.town_name}</td>
                            <td className="px-2">
                              {e_person.status_description}
                            </td>
                            <td>
                              {/* <Button onClick={() => showModalAppointments(e_person)}>Listar</Button> */}
                              <Link
                                to={{
                                  pathname: `/ficha-clinica/${e_person.person_id}`,
                                  state: {
                                    id: e_person.person_id,
                                    run: e_person.run,
                                    name: e_person.names,
                                    apellido: e_person.lastnames,
                                    fecha_nacimiento: e_person.birthdate,
                                  },
                                }}
                                style={{ color: "white" }}
                              >
                                <Button color="primary">Ver</Button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ListRecords;
