import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap';

const Restorations = (props) => {
    const { data, setData, name, hide } = props

    const materials = [
        { id: 1, value: "Material Temporal" },
        { id: 2, value: "Vidrio Ionómero" },
        { id: 3, value: "Resina Compuesta" },
        { id: 4, value: "Amalgama" },
    ]
    const surfaces_coronal = [
        { id: "coronal_oclusal", value: "Incisal/Oclusal" },
        { id: "coronal_vestibular", value: "Vestibular" },
        { id: "coronal_palatino", value: "Palatino/Lingual" },
        { id: "coronal_mesial", value: "Mesial" },
        { id: "coronal_distal", value: "Distal" }
    ]

    const surfaces_radicular = [
        { id: "radicular_vestibular", value: "Vestibular" },
        { id: "radicular_palatino", value: "Palatino/Lingual" },
        { id: "radicular_mesial", value: "Mesial" },
        { id: "radicular_distal", value: "Distal" }
    ]

    const states = [
        { id: 1, value: "Alfa" },
        { id: 2, value: "Beta" },
        { id: 3, value: "Charlie" },
        { id: 4, value: "Delta" }
    ]

    const handleInputChange = (e) => {
        const { id, value } = e.target
        setData({ ...data, [id]: value })
    }
    const handleInputCheckbox = (e) => {
        const { id, checked } = e.target
        setData({ ...data, surface: { ...data.surface, [id]: checked } })
    }


    return (hide &&
        <div>
            <Label className="form-control-label">{name}</Label>
            <FormGroup className="row m-0">
                <Col md="12">
                    <FormGroup className="row">
                        <Col sm={12}>
                            <Label className="form-control-label">Material</Label>
                            <Input
                                bsSize="sm"
                                id="material"
                                name="material"
                                type="select"
                                value={data.material}
                                onChange={e => handleInputChange(e)}>
                                <option hidden value="">Seleccionar</option>
                                {materials.map((material) =>
                                    <option
                                        key={material.id}
                                        value={material.value}
                                    >
                                        {material.value}
                                    </option>
                                )
                                }
                            </Input>
                        </Col>

                    </FormGroup>
                    <Label className="form-control-label">Ubicación/Superficie</Label>
                    <FormGroup className="row">
                        <Col sm={6}>
                            <Label className="form-control-label">Coronal</Label>
                            <Col sm={12}>
                                {surfaces_coronal.map((item) =>
                                    <div key={item.id} className="custom-control custom-checkbox custom-checkbox-primary">
                                        <Input
                                            className="custom-control-input"
                                            id={item.id}
                                            name={item.id}
                                            type="checkbox"
                                            checked={data.surface[item.id]}
                                            onChange={(e) => { handleInputCheckbox(e) }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={item.id}
                                        >{item.value}</label>
                                    </div>)
                                }
                            </Col>
                        </Col>
                        <Col sm={6}>
                            <Label className="form-control-label">Radicular</Label>
                            <Col sm={12}>
                                {surfaces_radicular.map((item) =>
                                    <div key={item.id} className="custom-control custom-checkbox custom-checkbox-primary">
                                        <Input
                                            className="custom-control-input"
                                            id={item.id}
                                            name={item.id}
                                            type="checkbox"
                                            checked={data.surface[item.id]}
                                            onChange={(e) => { handleInputCheckbox(e) }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={item.id}
                                        >{item.value}</label>
                                    </div>
                                )
                                }
                            </Col>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col sm={12}>
                            <Label className="form-control-label">Estado</Label>
                            <Input
                                bsSize="sm"
                                id="status"
                                name="status"
                                type="select"
                                value={data.status}
                                onChange={e => handleInputChange(e)}>
                                <option hidden value="">Seleccionar</option>
                                {states.map((state) =>
                                    <option
                                        key={state.id}
                                        value={state.value}
                                    >
                                        {state.value}
                                    </option>
                                )
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                </Col>
            </FormGroup>
        </div>
    )
}

export default Restorations



