import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Input, Table, Button } from "reactstrap";
import Axios from "helpers/axiosConfig";

export default function SearchSpecialistModal({ isOpen, toggle, setSpecialist }) {
  const [isLoading, setIsLoading] = useState(false);
  const [specialists, setSpecialists] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [message, setMessage] = useState("no hay coincidencias.");
  
  const fetchData = async () => {
    setIsLoading(true);
    const response = await Axios.get("user/user-specialist");
    if (response.data.error) {
      setMessage("Hubo un problema al obtener los especialistas.");
      setIsLoading(false);
      return;
    }
    if (response.data.result.length === 0) {
      setMessage("No se encontraron especialistas.");
      setIsLoading(false);
      return;
    }
    setSpecialists(response.data.result);
    setIsLoading(false);
  };

  const filteredSpecialists = specialists.filter(
    (specialist) =>
      specialist.professional_fullname
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      specialist.professional_email
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      specialist.professional_medical_area_name
        .toLowerCase()
        .includes(searchValue.toLowerCase())
  );

  function updateStateSpecialist(specialist) {
    setSpecialist(specialist);
    toggle();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Seleccionar Especialista</ModalHeader>
      <ModalBody className="pt-1">
        <Input
          type="text"
          placeholder="Buscar especialista..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <br />
        <div
          style={{
            maxHeight: "30rem",
            overflowY: "auto",
          }}
        >
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th>Especialista</th>
                <th>Area</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <p>Cargando...</p>
              ) : specialists.length === 0 ? (
                <p>{message}</p>
              ) : (
                filteredSpecialists.map((specialist, index) => (
                  <tr key={index}>
                    <td>{specialist.professional_fullname.toUpperCase()}</td>
                    <td>
                      {specialist.professional_medical_area_name.toUpperCase()}
                    </td>
                    <td>{specialist.professional_email}</td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() => updateStateSpecialist(specialist)}
                      >
                        Seleccionar
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
}
