import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "../routes/specialist.routes";
import ClinicalRecord from "views/pages/clinical-record/ClinicalRecord";
import Background from "views/pages/anamnesis/background";
import PhysicalParameters from "views/pages/anamnesis/anamnesis-medico-geriatra";
import DentalAnamnesis from "views/pages/anamnesis/dental-anamnesis";
import DentalAnamnesisU from "views/pages/anamnesis/dental-anamnesis-u";
import GeneralAnamnesisU from "views/pages/anamnesis/general-anamnesis-u";
import MedicalAnamnesisU from "views/pages/anamnesis/medical-anamnesis-u";
import MuscularExam from "views/pages/anamnesis/muscular-exam";
import ExtraOral from "views/pages/anamnesis/extra-oral";
import SoftTissuesExam from "views/pages/anamnesis/soft-tissues-exam";
import Odontogram from "views/pages/anamnesis/odontogram/odontogram";
import OdontogramUpdate from "views/pages/anamnesis/odontogram/odontogram-u";
import Periodontogram from "views/pages/anamnesis/peridontrograma";
import ToothPicker from "views/pages/anamnesis/tooth-picker";
import Conference from "views/pages/synchronous-communication/Conference";

class Specialist extends React.Component {
  state = {
    sidenavOpen: true,
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/specialist") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/tego.svg"),
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Route path="/antecedentes" component={Background} />
            <Route path="/examen-muscular" component={MuscularExam} />
            <Route path="/parametros-fisicos" component={PhysicalParameters} />
            <Route path="/anamnesis-dental" component={DentalAnamnesis} />
            <Route
              path="/actualizacion-anamnesis-dental"
              component={DentalAnamnesisU}
            />
            <Route
              path="/actualizacion-anamnesis-general"
              component={GeneralAnamnesisU}
            />
            <Route
              path="/actualizacion-anamnesis-medica"
              component={MedicalAnamnesisU}
            />
            <Route path="/examen-extra-oral" component={ExtraOral} />
            <Route path="/examen-tejidos-blandos" component={SoftTissuesExam} />
            <Route path="/selector-piezas-dentales" component={ToothPicker} />
            <Route path="/odontograma" component={Odontogram} />
            <Route
              path="/actualizacion-odontograma"
              component={OdontogramUpdate}
            />
            <Route path="/periodontograma" component={Periodontogram} />
            <Route path="/ficha-clinica/:id" component={ClinicalRecord} />
            <Route path="/atención/videoconferencia" component={Conference} />
            <Redirect from="*" to="/agenda" />
          </Switch>
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Specialist;
