import React, { useState, useEffect, useRef } from 'react'
import { Button, ButtonGroup, Col, Collapse, Fade } from 'reactstrap'
import Teeth from './teeth'
import Legend from './legend'
import ToothStatus from './tooth-status'
import Axios from 'helpers/axiosConfig'
import { Link } from 'react-router-dom';

const OdontogramUpdate = (props) => {
    const { location } = props
    const ANAM_ID = location.state.anamId
    const [odontogramId, setOdontogramId] = useState(0)

    const TEETH_STATUS_DEFAULT = [
        { tooth_id: 1, fdi_name: "1.1", tooth_state_id: 1 },
        { tooth_id: 2, fdi_name: "1.2", tooth_state_id: 1 },
        { tooth_id: 3, fdi_name: "1.3", tooth_state_id: 1 },
        { tooth_id: 4, fdi_name: "1.4", tooth_state_id: 1 },
        { tooth_id: 5, fdi_name: "1.5", tooth_state_id: 1 },
        { tooth_id: 6, fdi_name: "1.6", tooth_state_id: 1 },
        { tooth_id: 7, fdi_name: "1.7", tooth_state_id: 1 },
        { tooth_id: 8, fdi_name: "1.8", tooth_state_id: 1 },
        { tooth_id: 9, fdi_name: "2.1", tooth_state_id: 1 },
        { tooth_id: 10, fdi_name: "2.2", tooth_state_id: 1 },
        { tooth_id: 11, fdi_name: "2.3", tooth_state_id: 1 },
        { tooth_id: 12, fdi_name: "2.4", tooth_state_id: 1 },
        { tooth_id: 13, fdi_name: "2.5", tooth_state_id: 1 },
        { tooth_id: 14, fdi_name: "2.6", tooth_state_id: 1 },
        { tooth_id: 15, fdi_name: "2.7", tooth_state_id: 1 },
        { tooth_id: 16, fdi_name: "2.8", tooth_state_id: 1 },
        { tooth_id: 17, fdi_name: "3.1", tooth_state_id: 1 },
        { tooth_id: 18, fdi_name: "3.2", tooth_state_id: 1 },
        { tooth_id: 19, fdi_name: "3.3", tooth_state_id: 1 },
        { tooth_id: 20, fdi_name: "3.4", tooth_state_id: 1 },
        { tooth_id: 21, fdi_name: "3.5", tooth_state_id: 1 },
        { tooth_id: 22, fdi_name: "3.6", tooth_state_id: 1 },
        { tooth_id: 23, fdi_name: "3.7", tooth_state_id: 1 },
        { tooth_id: 24, fdi_name: "3.8", tooth_state_id: 1 },
        { tooth_id: 25, fdi_name: "4.1", tooth_state_id: 1 },
        { tooth_id: 26, fdi_name: "4.2", tooth_state_id: 1 },
        { tooth_id: 27, fdi_name: "4.3", tooth_state_id: 1 },
        { tooth_id: 28, fdi_name: "4.4", tooth_state_id: 1 },
        { tooth_id: 29, fdi_name: "4.5", tooth_state_id: 1 },
        { tooth_id: 30, fdi_name: "4.6", tooth_state_id: 1 },
        { tooth_id: 31, fdi_name: "4.7", tooth_state_id: 1 },
        { tooth_id: 32, fdi_name: "4.8", tooth_state_id: 1 }
    ]
    const [teethStatus, setTeethStatus] = useState(TEETH_STATUS_DEFAULT)

    const [toothSelected, setToothSelected] = useState()

    const TOOTH_STATUS_DEFAULT = {
        healthy: false,
        coronal_cavitie: '',
        radicular_cavitie: '',
        restoration: '',
        non_carious_injury: '',
        endodontically_treated: false,
        prosthesis: false,
    }

    const [toothStatus, setToothStatus] = useState(TOOTH_STATUS_DEFAULT)

    const statusRef = useRef(null)

    const getOdontogramId = async (ANAM_ID) => {
        const res = await Axios.get("odontogram/" + ANAM_ID)
            .then(response => {
                if (response.data.result[0] !== undefined) {
                    setOdontogramId(response.data.result[0].odontogram_id)
                }
            })
    }

    const getTeethStatus = async (odontogramId) => {
        const res = await Axios.get("odontogram/teeth/" + odontogramId).then(response => {
            if (response.data.result[0] !== undefined) {
                setTeethStatus(response.data.result)
            }
        })
    }

    const updateOdontogram = async (toothStatus) => {
        const res = await Axios.put("odontogram/teeth/" + odontogramId,
            {
                odontogramId: odontogramId,
                healthy: toothStatus.healthy,
                coronal_cavitie: toothStatus.coronal_cavitie,
                radicular_cavitie: toothStatus.radicular_cavitie,
                non_carious_injury: toothStatus.non_carious_injury,
                restoration: toothStatus.restoration,
                endodontically_treated: toothStatus.endodontically_treated,
                prosthesis: toothStatus.prosthesis,
                fdi_name: toothStatus.fdi_name,
            }
        )
    }


    useEffect(() => {
        getOdontogramId(ANAM_ID)
    }, [ANAM_ID]);

    useEffect(() => {
        getTeethStatus(odontogramId)
    }, [odontogramId, toothSelected])

    useEffect(() => {
        changeToothStatus(toothSelected)
    }, [toothSelected])



    const saveButton = () => {
        statusRef.current.handleStatus()
    }

    useEffect(() => {
        odontogramId !== 0 & toothStatus !== TOOTH_STATUS_DEFAULT && updateOdontogram(toothStatus)
    }, [toothStatus])


    const changeToothStatus = (toothSelected) => {
        getTeethStatus(odontogramId)
        teethStatus.map(tooth => tooth.fdi_name === toothSelected && setToothStatus(tooth))
    }


    return (
        <>
            <ButtonGroup style={{ zIndex: 100, position: 'absolute', top: '90%', left: '95%', transform: 'translate(-100%, -50%)' }}>
                <Button color="primary" size="sm"><Link style={{ color: "white" }} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/examen-extra-oral"
                }}>Extra Oral</Link>
                </Button>

                <Button color="primary" size="sm"><Link style={{ color: "white" }} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/selector-piezas-dentales"
                }}>Tejidos Blandos</Link>
                </Button>
                <Button color="primary" size="sm"><Link style={{ color: "white" }} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/selector-piezas-dentales"
                }}>Odontograma</Link>
                </Button>
                <Button color="info" size="sm"><Link style={{ color: "white" }} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/periodontograma"
                }}>Periodontograma</Link>
                </Button>
            </ButtonGroup>

            <div className="row px-5 m-0">
                <div className="row px-5 m-5">
                    <Col className='position-relative'>
                        <svg
                            className='position-relative'
                            xmlns="http://www.w3.org/2000/svg"
                            id="svg8"
                            width="100%"
                            height="600"
                            version="1.1"
                            viewBox="0 0 135 200"
                        >
                            <Teeth
                                status={teethStatus}
                                toothSelected={toothSelected}
                                setToothSelected={setToothSelected}
                            />
                            <Legend />
                        </svg>
                    </Col>
                    {toothSelected &&
                        <>
                            <Col>
                                <Collapse className="col-12 pt-6 my-5" isOpen={toothSelected ? true : false}>
                                    <Fade in={toothSelected ? true : false}  >
                                        <ToothStatus
                                            ref={statusRef}
                                            toothSelected={toothSelected}
                                            toothStatus={toothStatus}
                                            setToothStatus={setToothStatus}
                                        />
                                    </Fade>
                                </Collapse>
                                <div className="text-center">
                                    <Button color="primary"
                                        disabled={false}
                                        onClick={() => saveButton()}
                                    >
                                        Guardar estado del diente {toothSelected}
                                    </Button>
                                </div>
                            </Col>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default OdontogramUpdate