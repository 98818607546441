import axios from "axios";

const api = process.env.REACT_APP_API_URL;

/**
 * Obtiene un código secreto de la API.
 *
 * @param {string} token - Token de autenticación.
 * @param {string} conferenceName - Nombre de la conferencia.
 * @param {string} userId - ID del usuario.
 *
 * @returns {Promise} - Promesa que resuelve con el código secreto o rechaza con un error.
 */
export async function getSecretCode(token, conferenceName, userId) {
  let accessToken = "";
  const response = await axios
    .post(
      `${api}synchronous-communication/obtain`,
      {
        conferenceId: conferenceName,
        participantId: userId,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => console.error("Error:", error));
  if (response.resultCode === 200) {
    accessToken = response.result.code;
  }

  return accessToken;
}

export async function leftParticipant(token, conferenceName, userId) {
  const response  = await axios
    .post(
      `${api}synchronous-communication/left-participant`,
      {
        conferenceId: conferenceName,
        participantId: userId,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => console.error("Error:", error));

    console.log('Este es el response', response);
}
