import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Input, Table, Button } from "reactstrap";
import Axios from "helpers/axiosConfig";

export default function SearchPatientModal({
  isOpen,
  toggle,
  setPatient,
  userId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [message, setMessage] = useState("no hay coincidencias.");

  const fetchData = async (userId) => {
    try {
      setIsLoading(true);
      const endpoint = userId
        ? "person/listpatientbyprofessional/" + userId
        : "person/listPatient";
      const response = await Axios.get(endpoint);
      if (response.data.error) {
        setMessage("Hubo un problema al obtener los pacientes.");
        throw new Error(response.data.error);
      }
      if (response.data.result.length === 0) {
        setMessage("No se encontraron pacientes asociados a usted.");
        throw new Error(response.data.error);
      }
      setPatients(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredPatients = patients.filter(
    (patient) =>
      patient.names.toLowerCase().includes(searchValue.toLowerCase()) ||
      patient.lastnames.toLowerCase().includes(searchValue.toLowerCase()) ||
      patient.run.toLowerCase().includes(searchValue.toLowerCase())
  );

  function updateStatePerson(person) {
    setPatient(person);
    console.log(person);
    toggle();
  }

  useEffect(() => {
    fetchData(userId);
  }, [userId]);

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Seleccionar Paciente</ModalHeader>
      <ModalBody className="pt-1">
        <Input
          type="text"
          placeholder="Buscar paciente..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <br />
        <div
          style={{
            maxHeight: "30rem",
            overflowY: "auto",
          }}
        >
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th>Nombre</th>
                <th>RUN</th>
                <th>Localidad</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <p>Cargando...</p>
              ) : filteredPatients.length === 0 ? (
                <p>{message}</p>
              ) : (
                filteredPatients.map((patient, index) => (
                  <tr key={index}>
                    <td>
                      {patient.names.toUpperCase() +
                        " " +
                        patient.lastnames.toUpperCase()}
                    </td>
                    <td>{patient.run.toUpperCase()}</td>
                    <td>{patient.town_name.toUpperCase()}</td>
                    <td>{patient.status_description.toUpperCase()}</td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() => updateStatePerson(patient)}
                      >
                        Seleccionar
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
}
