import Dashboard from "views/pages/dashboards/Dashboard.js";
import ListRecord from "views/pages/clinical-record/list-records"
import SynchronousSessions from "views/pages/synchronous-communication/SynchronousSessions";
// import Referral from "views/pages/referral/referral"

const routes = [
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fa fa-columns text-primary",
    miniName: "D",
    component: Dashboard,
    layout: "/specialist",
  },
  {
    path: "/fichas-clinicas",
    name: "Fichas clinicas",
    miniName: "LF",
    icon: "ni fas fa-user-plus text-primary",
    component: ListRecord,
    layout: "/specialist",
  },
  {
    path: "/sesiones-sincronicas",
    name: "Sesiones Sincrónicas",
    miniName: "AA",
    icon: "ni fas fa-video text-primary",
    component: SynchronousSessions,
    layout: "/specialist",
  },
  // ,
  // {
  //   path: "/interconsultas",
  //   name: "Interconsultas",
  //   icon: "fa fa-address-book text-primary",
  //   miniName: "IC",
  //   component: Referral,
  //   layout: "/specialist"
  // }
];

export default routes;
