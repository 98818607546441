import React, { useState } from 'react'
import Tooth from './tooth'
import teethsvg from "../../../../file-json/teeth-svg.json"


const Teeth = ({status, toothSelected, setToothSelected}) => {
    //const [selected, setSelected] = useState(false)

    const colorLegend = {
        1: '#f5f0f1', //ausente 
        2: '#F4F2E5', //completo
        3: '#ff8080', //fracturado
        4: '#c4e5c9', //resto radicular
        5: '#878681', //implante
        6: '#8d99ae', //Prótesis fija
        7: '#eca053', //Lesión no cariosa
    }
    
    //const [toothSelected, setToothSelected] = useState("")

    const selectedColor = "#5e72e4"

    const handleClickTooth = (e) => {
        setToothSelected(e.currentTarget.id)
        e.currentTarget.id === toothSelected &&  setToothSelected('')
    }

    return (
        <>
            {
                teethsvg.map((tooth, index) =>
                    <Tooth
                        key={tooth.id}
                        id={tooth.id}
                        paths={tooth.path}
                        status={status[index]?.tooth_state_id}
                        toothSelected={toothSelected}
                        setToothSelected={setToothSelected}
                        colorLegend={colorLegend}
                        selectedColor={selectedColor}
                        //selected={selected}
                        //setSelected={setSelected}
                        handleClickTooth={handleClickTooth}
                    />
                )
            }
        </>
    )
}

export default Teeth