import React, { useCallback, useEffect, useState } from "react";
import {
  Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, UncontrolledTooltip, Label,
  Modal,
  ModalHeader,
  ModalBody, Card, Row
} from "reactstrap";
import Axios from "helpers/axiosConfig";
import AuthHelper from "helpers/AuthHelper";
import useList from "hooks/useList";
import { getList } from "helpers/ListHelper";
import toast from 'react-hot-toast';

const FileView = (props) => {
  const [fileInfo, setFileInfo] = useState();
  useEffect(() => {
    fileById();
  }, [fileById]);

  const openFile = (url) => {
    var win = window.open(
      process.env.REACT_APP_API_URL + "static/" + url,
      "_blank"
    );
    win.focus();
  };

  const fileById = async () => {
    const res = await Axios.get("review/file/" + props.id);
    if (res.data.result?.length) {
      setFileInfo(res.data.result[0]);
    }
  };
  return (
    <div>
      {fileInfo && (
        <Button
          outline
          color="info"
          onClick={() => {
            openFile(fileInfo.url);
          }}
        >
          {fileInfo.name}
        </Button>
      )}
    </div>
  );
};

const Reviews = (props) => {
  const [revText, setRevText] = useState("");
  const [file, setFile] = useState(null);
  const reviewTypes = useList("review/types");
  const [reviews, setReviews] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("-1");
  const [rtId, setRtId] = useState(-1);
  const [fileWillPost, setFileWillPost] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentReview, setCurrentReview] = useState("");
  const [currentReviewTitle, setCurrentReviewTitle] = useState("");
  const [currentReviewId, setCurrentReviewId] = useState(-1);

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const rut = props.rut;
  const personID = props.id;

  const fetchReviews = useCallback(async () => {
    const res = await getList("review/get/" + props.id);
    setReviews(res);
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const resetFile = () => {
    setFile(null);
  };

  const postReview = (e) => {
    e.preventDefault();
    if (fileWillPost && file) {
      withFile();
    } else {
      noFile();
    }
  };

  const noFile = async () => {
    const res = await Axios.post("review", {
      personId: props.id /* Id del paciente en atención */,
      userId: AuthHelper.getUserId() /* Id del usuario en sesión */,
      reviewTypeId: rtId,
      description: revText,
    });
    if (!res.data.error) {
      fetchReviews();
      setRevText("");
    }
  };

  const withFile = async () => {
    const res = await Axios.post("review/file/", {
      personId: props.id /* Id del paciente en atención */,
      userId: AuthHelper.getUserId() /* Id del usuario en sesión */,
      reviewTypeId: rtId,
      description: revText,
      url: rut + "/" + file.name,
      fileName: file.name,
    });
    if (!res.data.error) {
      const f = new FormData();
      f.append("file", file, file.name);
      await Axios.post("file/review/" + rut, f)
        .then((response) => {
          // console.log(response)
          resetFile();
          setFileWillPost(false);
        })
        .catch((error) => {
          console.log(error);
        });
      fetchReviews();
      setRevText("");
    }
  };

  const setStatusPerson = async (idPersona, status) => {
    const res = await Axios.put("person/set-status-person/", {
      status_id: status,
      personId: idPersona,
    });

    if (!res.data.error) {
      console.log("Status actualizado");
    }
  };

  const finalizarConsulta = async (e) => {
    e.preventDefault();

    const usr = await getList("person/typeuser/" + AuthHelper.getUserId());
    const professionalType = usr[0].name;

    if (professionalType === "Especialista") {
      setStatusPerson(personID, 10);
    } else {
      setStatusPerson(personID, 8);
    }

    window.location.href = "/fichas-clinicas";
  };

  const reviewType = (rTypeId) => {
    let rt = reviewTypes.filter((rt) => rt.id === rTypeId);
    return rt[0]?.name;
  };

  const filterRevs = () => {
    return selectedFilter === "-1"
      ? reviews
      : reviews.filter((rev) => String(rev.review_type_id) === selectedFilter);
  };

  const openModal = (review) => {
    setCurrentReviewTitle(review.names + " en " + reviewType(review.review_type_id));
    setCurrentReview(review.description);
    setCurrentReviewId(review.review_id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const editReview = async () => {
    const res = await Axios.put("review/file/", {
      prescription_id: currentReviewId,
      description: currentReview
    });
    if (!res.data.error) {
      toast.success("¡Comentario actualizado correctamente!", {
        id: 'success'
      })
      fetchReviews();
      closeModal();
    } else {
      toast.error("¡Error al actualizar el comentario!")
    }
  }

  const deleteReview = async () => {
    const res = await Axios.delete("review/file/" + currentReviewId);
    if (!res.data.error) {
      toast.success("¡Comentario eliminado correctamente!", {
        id: 'success'
      })
      fetchReviews();
      closeModal();
    } else {
      toast.error("¡Error al eliminar el comentario!")
    }
  }

  return !reviewTypes ? (
    <div></div>
  ) : (<><Modal size="md" isOpen={modalOpen} backdrop={false} centered fade={false}>
    <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
      Edición de comentario
    </ModalHeader>
    <ModalBody className="pt-1">
      <Card style={{ boxShadow: '0px 0px 0px' }} >
        <h4>{currentReviewTitle}</h4>
        <Input
          type="textarea"
          name="review_update"
          value={currentReview}
          readOnly={false}
          maxLength="1000"
          onChange={e => setCurrentReview(e.target.value)}
        />
        <br />
        <Button color="primary" onClick={editReview}>Actualizar</Button>
        <br />
        <Button color="danger" onClick={deleteReview}>Eliminar</Button>
        <br />
        <Button color="secondary" onClick={closeModal}>Cancelar</Button>
      </Card>
    </ModalBody>
  </Modal>
    <div>
      <strong>Filtros:{"  "}</strong>
      <FormGroup check inline>
        <Label check>
          <input
            type="radio"
            value="-1"
            checked={selectedFilter === "-1"}
            onChange={(e) => setSelectedFilter(e.target.value)}
          />{" "}
          Todos
        </Label>
      </FormGroup>
      {reviewTypes.map((rt) => (
        <FormGroup check inline key={rt.id}>
          <Label check>
            <input
              type="radio"
              value={rt.id}
              checked={selectedFilter == rt.id}
              onChange={(e) => setSelectedFilter(e.target.value)}
            />{" "}
            {rt.name}
          </Label>
        </FormGroup>
      ))}
      <hr />
      <div
        className="timeline timeline-one-side"
        data-timeline-axis-style="none"
        data-timeline-content="axis"
        style={{ overflow: "scroll", height: "450px" }}
      >
        {filterRevs().map((rev) => (
          <div key={rev.review_id} className="timeline-block">
            <span className="timeline-step">
              <small className="text-muted font-weight-bold" id="rev_date">
                {rev.c_time}
                <br />
                {rev.c_date}
              </small>
              <UncontrolledTooltip target="rev_date">
                {rev.c_date}/{rev.c_year}
              </UncontrolledTooltip>
            </span>
            <div className="timeline-content">
              <Row>
                <Col className="col-10">
                  <h5 className="mt-2 mb-0">
                    {rev.names} en {reviewType(rev.review_type_id)}
                  </h5>
                  <p className="text-sm mt-1 mb-0">{rev.description}</p>
                  {Boolean(rev.has_file) && <FileView id={rev.review_id} />}
                </Col>
                <Col className="col-2"><Button onClick={() => openModal(rev)} color="primary" className="far fa-edit">
                </Button>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </div>
      <Form onSubmit={postReview}>
        {rtId == 4 && (
          <div>
            <br />
            Revise la plantilla principal de recetas{" "}
            <a
              target="_blank"
              without
              rel="noopener noreferrer"
              href="https://drive.google.com/drive/u/4/folders/1QyKDDUkRrbq4DIaseKIuaoba9XraAA96"
            >
              aquí
            </a>
          </div>
        )}
        <br />
        <FormGroup className="row">
          <Label className="form-control-label pt-3" md="2">
            <b>Tipo de comentario</b>
          </Label>
          <Col md="10">
            <Input
              type="select"
              onChange={(e) => {
                setRtId(e.target.value);
              }}
              required
            >
              <option hidden value={-1}>
                (Seleccione)
              </option>
              {reviewTypes.map((rt) => (
                <option key={rt.id} value={rt.id}>
                  {rt.name}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label md="3">
            Adjuntar archivo?{" "}
            <label className="custom-toggle custom-toggle-info mr-1">
              <Input
                type="checkbox"
                checked={fileWillPost}
                onChange={() => {
                  setFileWillPost(!fileWillPost);
                }}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="No"
                data-label-on="Sí"
              />
            </label>
          </Label>
          <Col md="9">
            {fileWillPost && (
              <InputGroup>
                <Input
                  autoComplete="off"
                  type="text"
                  name="treatment_plan"
                  readOnly
                  placeholder="Elija archivo"
                  value={file ? file.name : ""}
                />
                <InputGroupAddon addonType="append">
                  {file ? (
                    <Button type="button" color="danger" onClick={resetFile}>
                      Deshacer
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      color="info"
                      onClick={() => {
                        document.getElementById("rFile").click();
                      }}
                    >
                      Elegir archivo
                    </Button>
                  )}
                </InputGroupAddon>
              </InputGroup>
            )}
            <input
              type="file"
              id="rFile"
              style={{ visibility: "hidden" }}
              onChange={handleFileChange}
              required={fileWillPost}
            />
          </Col>
        </FormGroup>
        <InputGroup>
          <Input
            type="textarea"
            value={revText}
            maxLength="1000"
            onChange={(e) => {
              setRevText(e.target.value);
            }}
            placeholder="Escriba aquí su comentario"
            required={!fileWillPost}
          />
          <Button color="primary" disabled={rtId == -1} type="submit">
            Subir
          </Button>
        </InputGroup>

        <br />
        <div className="text-center px-2">
          <Button color="primary" onClick={finalizarConsulta}>
            Finalizar atención
          </Button>
        </div>
      </Form>
    </div>
  </>
  );
};

export default Reviews;