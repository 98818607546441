import React from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import moment from "moment";

export default function SynchronousSessionDetails({
  session,
  isOpen,
  onClose,
}) {
  if (session == null) return null;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={onClose}>Detalles de sesión sincrónica</ModalHeader>
      <ModalBody>
        <Table responsive>
          <tbody>
            <tr>
              <th>N° de sesión</th>
              <td>{session.id}</td>
            </tr>
            <tr>
              <th>Dentista</th>
              <td>
                {session.dentist_names.toUpperCase() +
                  " " +
                  session.dentist_lastnames.toUpperCase()}
              </td>
            </tr>
            <tr>
              <th>Especialista</th>
              <td>
                {session.specialist_names.toUpperCase() +
                  " " +
                  session.specialist_lastnames.toUpperCase()}
              </td>
            </tr>
            <tr>
              <th>Paciente</th>
              <td>
                {session.patient_names.toUpperCase() +
                  " " +
                  session.patient_lastnames.toUpperCase()}
              </td>
            </tr>
            <tr>
              <th>RUN Paciente</th>
              <td>{session.patient_run.toUpperCase()}</td>
            </tr>
            <tr>
              <th>Area</th>
              <td>{session.medical_area_name.toUpperCase()}</td>
            </tr>
            <tr>
              <th>Descripción</th>
              <td>{session.description.toUpperCase()}</td>
            </tr>
            <tr>
              <th>Estado</th>
              <td>{session.status_name.toUpperCase()}</td>
            </tr>
            <tr>
              <th>Fecha</th>
              <td>
                {session.date
                  ? moment(session.date).format("DD-MM-YYYY")
                  : "Por asignar"}
              </td>
            </tr>
            <tr>
              <th>Horario</th>
              <td>
                {session.starts_at
                  ? moment(session.starts_at, "HH:mm").format("HH:mm")
                  : "Por asignar"}{" "}
                a{" "}
                {session.ends_at
                  ? moment(session.ends_at, "HH:mm").format("HH:mm")
                  : "Por asignar"}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex flex-column text-muted">
          <small>
            Creada el: {moment(session.created_at).format("DD-MM-YYYY HH:mm")}
          </small>
          <small>
            Ultima actualización:{" "}
            {moment(session.updated_at).format("DD-MM-YYYY HH:mm")}
          </small>
        </div>
      </ModalBody>
    </Modal>
  );
}
