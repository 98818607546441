import Calendar from "views/pages/appointment/calendar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from "reactstrap";
import Schedule from "views/pages/appointment/schedule";
import {
  getSynchronousEvents,
  updateSynchronousSession,
} from "../api/synchronous-sessions";
import Axios from "helpers/axiosConfig";
import { getCalendarEvents } from "views/pages/appointment/api/CalendarEvents";

export default function ScheduleSynchronousSessionModal({
  session,
  isOpen,
  onClose,
}) {
  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
  const defaultRangeAgenda = { minTime: "07:00", maxTime: "21:00" };
  const update = false;
  const [currentEvents, setCurrentEvents] = useState([]);
  const [appointment, setAppointment] = useState({});

  const setSessionDate = async () => {
    if (!appointment.cons_appo_start || !appointment.cons_appo_end) {
      alert("Seleccione un horario");
      return;
    }
    const sessionRequestBody = {
      date: appointment.cons_appo_date,
      startsAt: appointment.cons_appo_start,
      endsAt: appointment.cons_appo_end,
      place: appointment.cons_appo_place,
      id: session.id,
      statusId: 2, // estado Agendado
    };
    const response = await updateSynchronousSession(sessionRequestBody);
    console.log(response);
    onClose();
  };

  const fetchData = async (session) => {
    const sessions = await getCalendarEvents({
      professionalId: session.dentist_id,
    });
    console.log("sessions", sessions);
    setCurrentEvents(sessions);
  };

  useEffect(() => {
    if (session) {
      try {
        fetchData(session);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [session]);

  if (!session) return null;

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader className="pb-2" toggle={onClose}>
        <div className="row">
          <Label size="sm" sm={12} className="form-control-label">
            Paciente:{" "}
            <span style={{ color: "#DC3545" }}>
              {session.patient_names + " " + session.patient_lastnames}
            </span>
          </Label>
        </div>
      </ModalHeader>
      <ModalBody className="p-2">
        <Container>
          <Row>
            <Col>
              <Form>
                <FormGroup className="mb-1">
                  <Label size="sm" className="form-control-label mb-0">
                    Dentista
                  </Label>
                  <Input
                    readOnly
                    bsSize={"sm"}
                    value={`${session.dentist_names.toUpperCase()} ${session.dentist_lastnames.toUpperCase()}`}
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label size="sm" className="form-control-label mb-0">
                    Especialista
                  </Label>
                  <Input
                    readOnly
                    bsSize={"sm"}
                    value={`${session.specialist_names.toUpperCase()} ${session.specialist_lastnames.toUpperCase()}`}
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label size="sm" className="form-control-label mb-0">
                    Área
                  </Label>
                  <Input
                    readOnly
                    bsSize={"sm"}
                    value={session.medical_area_name.toUpperCase()}
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label size="sm" className="form-control-label mb-0">
                    Descripción
                  </Label>
                  <Input
                    readOnly
                    bsSize={"sm"}
                    value={session.description.toUpperCase()}
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label size="sm" className="form-control-label mb-0">
                    Lugar
                  </Label>
                  <Input
                    bsSize={"sm"}
                    value={appointment.cons_appo_place}
                    onChange={(e) => {
                      setAppointment({
                        ...appointment,
                        cons_appo_place: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <div className="mb-3">
                  <Calendar setSelectedDay={setSelectedDay} />
                </div>
              </Form>
            </Col>
            <Col>
              <Schedule
                selectedDay={selectedDay}
                rangeAgenda={defaultRangeAgenda}
                calendarEvents={currentEvents}
                setCalendarEvents={setCurrentEvents}
                appointment={appointment}
                setAppointment={setAppointment}
                update={update}
              />
              <div className="w-100 d-flex justify-content-end mt-3 mb-2">
                <Button color="primary" onClick={() => setSessionDate()}>
                  Agendar sesión
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}
