import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Button,
  InputGroup,
  Container,
  InputGroupAddon,
  Tooltip,
} from "reactstrap";
import SearchPatientModal from "./SearchPatientModal";
import SearchSpecialistModal from "./SearchSpecialistModal";
import { newSynchronousSession } from "../api/synchronous-sessions";
import SearchDentistModal from "./SearchDentistModal";

export default function NewSynchronousSessionButton({ user, onSave }) {
  // modals
  const [newAssistedReferralModal, setNewAssistedReferralModal] =
    useState(false);
  const [searchSpecialistModal, setSearchSpecialistModal] = useState(false);
  const [searchPatientModal, setSearchPatientModal] = useState(false);
  const [searchProfessionalModal, setSearchProfessionalModal] = useState(false);

  // tooltips
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // form data
  const [dentist, setDentist] = useState({});
  const [patient, setPatient] = useState({});
  const [specialist, setSpecialist] = useState({});
  const [description, setDescription] = useState("");

  async function createSynchronousSession() {
    try {
      const session = {
        dentistId: dentist.professional_id,
        specialistId: specialist.professional_id,
        patientId: patient.person_id,
        description: description,
        medicalAreaId: specialist.professional_medical_area_id,
      };
      console.log(session);
      const response = await newSynchronousSession(session);
      console.log(response);
      setNewAssistedReferralModal(false);
      await onSave();
    } catch (error) {
      console.error("Error creating synchronous session:", error);
    }
  }

  useEffect(() => {
    if (user.role === 4) {
      setDentist({
        professional_id: user.id,
        professional_fullname: user.name,
      });
    }
  }, [user]);

  return (
    <>
      <Button color="primary" onClick={() => setNewAssistedReferralModal(true)}>
        Agendar sesión sincrónica
      </Button>
      <Modal size="lg" isOpen={newAssistedReferralModal}>
        <ModalHeader toggle={() => setNewAssistedReferralModal(false)}>
          Agendar sesión sincrónica
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <label>Dentista</label>
              {user.role === 4 ? (
                <Input
                  readOnly
                  type="text"
                  placeholder="Dentista"
                  value={
                    dentist.professional_fullname
                      ? dentist.professional_fullname.toUpperCase()
                      : ""
                  }
                />
              ) : (
                <InputGroup>
                  <Input
                    readOnly
                    value={
                      dentist.professional_fullname
                        ? dentist.professional_fullname.toUpperCase()
                        : ""
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <i
                      className="fas fa-search btn btn-primary"
                      onClick={() => setSearchProfessionalModal(true)}
                    />
                  </InputGroupAddon>
                  <SearchDentistModal
                    isOpen={searchProfessionalModal}
                    toggle={() => setSearchProfessionalModal(false)}
                    setDentist={setDentist}
                  />
                </InputGroup>
              )}
            </FormGroup>
            <FormGroup>
              <label>Paciente</label>
              <InputGroup>
                <Input
                  readOnly
                  value={
                    patient.names
                      ? patient.names.toUpperCase() +
                        " " +
                        patient.lastnames.toUpperCase()
                      : ""
                  }
                />
                <InputGroupAddon addonType="append">
                  <i
                    className="fas fa-search btn btn-primary"
                    onClick={() => setSearchPatientModal(true)}
                  />
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <label>Especialista</label>
                  <InputGroup>
                    <Input
                      readOnly
                      value={
                        specialist.professional_fullname
                          ? specialist.professional_fullname.toUpperCase()
                          : ""
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <i
                        className="fas fa-search btn btn-primary"
                        onClick={() => setSearchSpecialistModal(true)}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Area</label>
                  <Input
                    type="text"
                    placeholder="Area"
                    readOnly
                    value={
                      specialist.professional_medical_area_name
                        ? specialist.professional_medical_area_name.toUpperCase()
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <label>Descripción</label>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            <Container>
              <Row className="align-items-center">
                <Button color="primary" onClick={createSynchronousSession}>
                  Solicitar sesión sincrónica
                </Button>
                <i className="fas fa-question-circle" id="tooltip" />
                <Tooltip
                  isOpen={tooltipOpen}
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                  target="tooltip"
                >
                  Se creará una solicitud de sesión sincrónica para que el
                  enrolador a cargo verifique los horarios disponibles de ambos
                  profesionales junto al paciente.
                </Tooltip>
              </Row>
            </Container>
          </Form>
        </ModalBody>
      </Modal>
      <SearchSpecialistModal
        isOpen={searchSpecialistModal}
        toggle={() => setSearchSpecialistModal(!searchSpecialistModal)}
        setSpecialist={setSpecialist}
      />
      <SearchPatientModal
        isOpen={searchPatientModal}
        toggle={() => setSearchPatientModal(!searchPatientModal)}
        setPatient={setPatient}
      />
    </>
  );
}
