import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from "../../../helpers/axiosConfig";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "reactstrap";
import Reviews from "./components/Reviews";

// Mapa de motivos de la consulta
const motiveDescriptions = {
  bleeding: "Hemorragia bucal",
  cavities: "Manchas o heridas (>1 mes)",
  fracture: "Pérdida o fractura de restauraciones o prótesis dentales",
  pain: "Dolor de causa dentaria",
  swelling: "Hinchazón",
  trauma: "Trauma",
  treatment: "Requiere tratamiento",
  wounds: "Lesiones en mucosa por desajuste protésico",
};

// Componente para mostrar los motivos de la consulta
const MotivosConsulta = ({ data }) => {
  return (
    <ul className="pl-0">
      {Object.entries(data)
        .filter(([key, value]) => motiveDescriptions[key] && value === 1) // Filtra los que tienen 1 y existen en el mapa
        .map(([key]) => (
          <li key={key}>{motiveDescriptions[key]}</li>
        ))}
    </ul>
  );
};

// Componente principal
export default function ClinicalRecord() {
  const { id } = useParams();

  const [patient, setPatient] = useState({
    names: "",
    lastnames: "",
    run: "",
  });
  const [anamnesisId, setAnamnesisId] = useState(-1);
  const [medicalPreConsult, setMedicalPreConsult] = useState({});
  const [patientDeseases, setPatientDeseases] = useState([]);
  const [medicalData, setMedicalData] = useState({});
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getAnamnsesisId(id) {
    try {
      const res = await Axios.get(`/geriatrical-odonto-anamnesis/crud/${id}`);
      if (res.data.result.length > 0) {
        setAnamnesisId(res.data.result[0].dental_anamnesis_id);
      } else {
        setAnamnesisId(-1);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  }

  useEffect(() => {
    try {
      Axios.get("/person/" + id).then((res) => {
        if (res.data.result?.length) {
          setPatient(res.data.result[0]);
        } else {
          setPatient(null);
        }
      });
      Axios.get("/person/pre-medical-consultation/" + id).then((res) => {
        setMedicalPreConsult(res.data.result[0]);
      });
      Axios.get("general-anamnesis/disease/" + id).then((res) => {
        setPatientDeseases(res.data.result);
      });
      Axios.get("general-anamnesis/data/" + id).then((res) => {
        setMedicalData(res.data.result[0]);
      });
      Axios.get("geriatrical-odonto-anamnesis/diag-treat/" + id).then((res) => {
        setDiagnosisList(res.data.result);
      });
      getAnamnsesisId(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!patient) {
    return <div>Paciente no encontrado</div>;
  }

  return (
    <Container className="mt-2">
      <Row>
        <Col>
          <h1>Ficha clinica del paciente</h1>
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <Card>
            <CardHeader>
              <b>Información del paciente</b>
            </CardHeader>
            <CardBody>
              <Table borderless>
                <tr>
                  <th>Nombre(s)</th>
                  <td>{patient.names.toUpperCase()}</td>
                </tr>
                <tr>
                  <th>Apellido(s)</th>
                  <td>{patient.lastnames.toUpperCase()}</td>
                </tr>
                <tr>
                  <th>RUN</th>
                  <td>{patient.run}</td>
                </tr>
                <tr>
                  <th>Edad</th>
                  <td>{getAge(patient.birthdate)} años</td>
                </tr>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <b>Alertas Medicas</b>
            </CardHeader>
            <CardBody>
              <Table borderless>
                <tr>
                  <th>Urgencia Dental</th>
                  <td>{medicalPreConsult.urgency ? "Si" : "No"}</td>
                </tr>
                <tr>
                  <th>Motivo consulta</th>
                  <td>
                    <MotivosConsulta data={medicalPreConsult} />
                  </td>
                </tr>
                <tr>
                  <th>Otros</th>
                  <td>
                    {medicalPreConsult.other ? medicalPreConsult.other : "N/A"}
                  </td>
                </tr>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <Card>
            <CardHeader>
              <b>Exámenes orales 3D</b>
            </CardHeader>
            <CardBody>
              <Button disabled={anamnesisId === -1} color="primary">
                <Link
                  to={{
                    state: {
                      id: id,
                      run: patient.run,
                      name: patient.names,
                      apellido: patient.lastnames,
                      anamId: anamnesisId,
                    },
                    pathname: "/examen-extra-oral",
                  }}
                >
                  <i className="ni ni-app align-middle text-white" />
                  <span className="align-middle text-white">Ver modelos</span>
                </Link>
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <b>Links Rapidos</b>
            </CardHeader>
            <CardBody>
              <Link
                to={{
                  state: {
                    id: id,
                    run: patient.run,
                    name: patient.names,
                    apellido: patient.lastnames,
                  },
                  pathname: "/actualizacion-anamnesis-general",
                }}
              >
                <i className="ni ni-bold-right"></i>Anamnesis General
              </Link>
              <br />
              <Link
                to={{
                  state: {
                    id: id,
                    run: patient.run,
                    name: patient.names,
                    apellido: patient.lastnames,
                  },
                  pathname: "/actualizacion-anamnesis-medica",
                }}
              >
                <i className="ni ni-bold-right"></i>Anamnesis Médico Geriátrica
              </Link>
              <br />
              <Link
                to={{
                  state: {
                    id: id,
                    run: patient.run,
                    name: patient.names,
                    apellido: patient.lastnames,
                  },
                  pathname: "/actualizacion-anamnesis-dental",
                }}
              >
                <i className="ni ni-bold-right"></i>Anamnesis Odonto Geriátrica
              </Link>
              <br />
              <Link
                to={{
                  state: {
                    id: id,
                    run: patient.run,
                    name: patient.names,
                    apellido: patient.lastnames,
                  },
                  pathname: "/interconsultas",
                }}
              >
                <i className="ni ni-bold-right"></i>Interconsultas
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>Enfermedades</b>
            </CardHeader>
            <CardBody>
              <ul>
                {patientDeseases.map((disease, index) => (
                  <li key={index}>{disease.name}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <b>Alergias</b>
            </CardHeader>
            <CardBody>
              {medicalData?.allergies ? medicalData.allergies : "N/A"}
            </CardBody>
            <CardHeader>
              <b>Alergias a farmacos</b>
            </CardHeader>
            <CardBody>
              {medicalData?.medicine_allergies
                ? medicalData.medicine_allergies
                : "N/A"}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <b>Fármacos y Posología</b>
            </CardHeader>
            <CardBody>
              {medicalData?.medicine_dose ? medicalData.medicine_dose : "N/A"}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>Diagnóstico odontológico</b>
            </CardHeader>
            <CardBody>
              <ul>
                {diagnosisList.map((diagnosis, index) => (
                  <li key={index}>{diagnosis.odontological_diag}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>Plan de tratamiento</b>
            </CardHeader>
            <CardBody>
              <ul>
                {diagnosisList.map((diagnosis, index) => (
                  <li key={index}>{diagnosis.treatment_plan}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>Comentarios</b>
            </CardHeader>
            <CardBody>
              <Reviews id={id} rut={patient.run} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
