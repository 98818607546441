import React, { useState } from "react";

const Tooth = ({ id, paths, status, teethStatusList, setTeethStatusList, setStrokeWidthList, strokeWidthList, setCurrentTooth }) => {

    //OLD COLORS
    // const colorLegend={
    //     1:'#222526', //ausente 
    //     2:'#F4F2E5', //completo
    //     3:'#E20000', //fracturado
    //     4:'#006666', //resto radicular
    //     5:'#878681' //implante
    // }

    const colorLegend = {
        1: '#f5f0f1', //ausente 
        2: '#F4F2E5', //completo
        3: '#ff8080', //fracturado
        4: '#c4e5c9', //resto radicular
        5: '#878681', //implante
        6: '#8d99ae', //Prótesis fija
        7: '#eca053', //Lesión no cariosa
    }

    const handleClickTooth = (e) => {
        const toothId = e.currentTarget.id;
        if (strokeWidthList[toothId] == null) {
            setStrokeWidthList(prev => ({ ...prev, [toothId]: '1.1' }));
        }
        else {
            setStrokeWidthList(prev => ({ ...prev, [toothId]: null }));
        }
        addTeeth(toothId);
    }

    const addTeeth = (tooth) => {
        var auxTeethStatusList = teethStatusList;
        if (auxTeethStatusList.includes(tooth)) {
            auxTeethStatusList = auxTeethStatusList.filter(item => item !== tooth);
            setCurrentTooth(getJsonValue(auxTeethStatusList));
        }
        else {
            auxTeethStatusList.push(tooth);
            setCurrentTooth(tooth);
        }
        setTeethStatusList(auxTeethStatusList);
    }

    const getJsonValue = (JsonObj) => {
        const val = Object.values(JsonObj);
        return val.length > 0 ? val[0] : '';
    };

    return (
        <>
            <g
                id={id}
                fill="none"
                stroke={status !== 1 ? "#6a6565" : ""}
                strokeLinejoin="miter"
                strokeOpacity="1"
                strokeWidth='0.265'
                display="inline"
                transform="translate(-35.628 -25.806)"
                onClick={handleClickTooth}
            >
                {paths.map((path, index) => {
                    if (index == 0) {
                        return <path
                            key={id + index}
                            id={id}
                            strokeLinecap="butt"
                            d={path.d}
                            fill={colorLegend[status]}
                            stroke={strokeWidthList[id] ? '#6a6565' : ''}
                            strokeWidth={strokeWidthList[id] ? strokeWidthList[id] : '0.265'}
                        >
                        </path>
                    }
                    else {
                        return <path
                            key={id + index}
                            id={id}
                            strokeLinecap="butt"
                            d={path.d}
                            fill={colorLegend[status]}
                        >
                        </path>
                    }

                })}
            </g>
        </>
    )
}

export default Tooth