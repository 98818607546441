import React from "react";
import teeth from "./teeth-svg.json"
import Tooth from "./Tooth";

function Teeth({ teethStatus, setTeethStatus, teethStatusList, setTeethStatusList, strokeWidthList, setStrokeWidthList, setCurrentTooth }) {

    const handleTeethStatus = (e) => {
        const newTeethStatus = teethStatus.map((tooth, index) =>
            tooth.fdi_name === e.currentTarget.id ? { ...tooth, tooth_state_id: (tooth.tooth_state_id === 7 ? 1 : tooth.tooth_state_id + 1) } : tooth
        )
        setTeethStatus(newTeethStatus)
    }

    return (
        <>
            {
                teeth.map((tooth, index) =>
                    <Tooth key={tooth.id} status={teethStatus[index]?.tooth_state_id} handleTeethStatus={handleTeethStatus} id={tooth.id} paths={tooth.path} teethStatusList={teethStatusList} setTeethStatusList={setTeethStatusList} setStrokeWidthList={setStrokeWidthList} strokeWidthList={strokeWidthList} setCurrentTooth={setCurrentTooth}/>
                )
            }
        </>
    );
}

export default Teeth;
