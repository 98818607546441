import Login from "views/pages/login/Login";
import LoginExp from "views/pages/login/LoginExp";

const routes = [
  {
    path: "/login/old",
    component: LoginExp,
    layout: "/login/old",
  },
  {
    path: "/login",
    component: Login,
    layout: "/login",
  },
];

export default routes;
