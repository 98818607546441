import React, { useState, useEffect, Suspense, useRef } from 'react'
import Axios from '../../../helpers/axiosConfig'
import { Button, ButtonGroup, ButtonToolbar, Tooltip, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Fade } from 'reactstrap'
import { Canvas } from "react-three-fiber";
import CameraControls from '../../../components/Fantoma/CameraControls'
import FantomaOdontogram from '../../../components/Fantoma/FantomaOdontogram'
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import Legend from "views/pages/anamnesis/legend-svg.jsx";
import Teeth from "components/Fantoma/FormsExams/Teeth.jsx";
import ToothStatus from "views/pages/anamnesis/odontogram/tooth-status";
import SummaryTable from './odontogram/summary-table';

const ToothPicker = ({ location, update }) => {

    const TOOTH_STATUS_DEFAULT = {
        healthy: false,
        coronal_cavitie: '',
        radicular_cavitie: '',
        restoration: '',
        non_carious_injury: '',
        endodontically_treated: false,
        prosthesis: false,
    }

    const [canvasSize, setCanvasSize] = useState({ height: undefined });
    const [gumOpacity, setGumOpacity] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [showSummaryModal, setShowSummaryModal] = useState(false)
    const [opacityTooltipOpen, setOpacityTooltipOpen] = useState(false);

    const statusRef = useRef(null);
    const [teethStatusList, setTeethStatusList] = useState([]);
    const [strokeWidthList, setStrokeWidthList] = useState({});
    const [currentTooth, setCurrentTooth] = useState('');
    const [toothState, setToothState] = useState(1);
    const [toothStatus, setToothStatus] = useState(TOOTH_STATUS_DEFAULT);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [teethSelected, setTeethSelected] = useState([]);
    const [isAnimated, setIsAnimated] = useState(false);
    const [message, setMessage] = useState('');

    const resizeCanvas = () => {
        const navbarSize = document.getElementsByClassName('navbar-top')[0];
        const handleResize = () => {
            setCanvasSize({
                width: window.innerWidth - (update !== undefined ? (250) : (0)) - navbarSize.offsetWidth,
                height: window.innerHeight - (update !== undefined ? (250) : (0)) - navbarSize.offsetHeight
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }

    const updateTeethOdontogram = async (toothStatus) => {
        if (teethSelected !== "") {
            const teethSelectedAux = teethSelected.replaceAll(' ', '');
            if (teethSelectedAux.includes(',')) {
                const teethSelectedArray = teethSelectedAux.split(',');
                await Axios.put("odontogram/teeth",
                    {
                        odontogramId: odontogramId,
                        healthy: toothStatus.healthy,
                        coronal_cavitie: toothStatus.coronal_cavitie,
                        radicular_cavitie: toothStatus.radicular_cavitie,
                        non_carious_injury: toothStatus.non_carious_injury,
                        restoration: toothStatus.restoration,
                        endodontically_treated: toothStatus.endodontically_treated,
                        prosthesis: toothStatus.prosthesis,
                        fdi_names: teethSelectedArray,
                    }
                )
            }
            else {
                await Axios.put("odontogram/teeth/" + odontogramId,
                    {
                        odontogramId: odontogramId,
                        healthy: toothStatus.healthy,
                        coronal_cavitie: toothStatus.coronal_cavitie,
                        radicular_cavitie: toothStatus.radicular_cavitie,
                        non_carious_injury: toothStatus.non_carious_injury,
                        restoration: toothStatus.restoration,
                        endodontically_treated: toothStatus.endodontically_treated,
                        prosthesis: toothStatus.prosthesis,
                        fdi_name: toothStatus.fdi_name,
                    });
            }
        }
    }

    useEffect(() => {
        if (currentTooth !== '') {
            changeToothStatus();
        }
        else {
            setToothStatus(TOOTH_STATUS_DEFAULT);
        }
    }, [currentTooth])

    const changeToothStatus = () => {
        teethStatus.map(tooth => {
            if (tooth.fdi_name === currentTooth) {
                setToothState(tooth.tooth_state_id);
                setToothStatus(tooth);
                setTeethSelected(getTeethSelected());
            }
        });
    }

    const initialTeethStatus = [
        { fdi_name: "1.1", tooth_state_id: 1 },
        { fdi_name: "1.2", tooth_state_id: 1 },
        { fdi_name: "1.3", tooth_state_id: 1 },
        { fdi_name: "1.4", tooth_state_id: 1 },
        { fdi_name: "1.5", tooth_state_id: 1 },
        { fdi_name: "1.6", tooth_state_id: 1 },
        { fdi_name: "1.7", tooth_state_id: 1 },
        { fdi_name: "1.8", tooth_state_id: 1 },
        { fdi_name: "2.1", tooth_state_id: 1 },
        { fdi_name: "2.2", tooth_state_id: 1 },
        { fdi_name: "2.3", tooth_state_id: 1 },
        { fdi_name: "2.4", tooth_state_id: 1 },
        { fdi_name: "2.5", tooth_state_id: 1 },
        { fdi_name: "2.6", tooth_state_id: 1 },
        { fdi_name: "2.7", tooth_state_id: 1 },
        { fdi_name: "2.8", tooth_state_id: 1 },
        { fdi_name: "3.1", tooth_state_id: 1 },
        { fdi_name: "3.2", tooth_state_id: 1 },
        { fdi_name: "3.3", tooth_state_id: 1 },
        { fdi_name: "3.4", tooth_state_id: 1 },
        { fdi_name: "3.5", tooth_state_id: 1 },
        { fdi_name: "3.6", tooth_state_id: 1 },
        { fdi_name: "3.7", tooth_state_id: 1 },
        { fdi_name: "3.8", tooth_state_id: 1 },
        { fdi_name: "4.1", tooth_state_id: 1 },
        { fdi_name: "4.2", tooth_state_id: 1 },
        { fdi_name: "4.3", tooth_state_id: 1 },
        { fdi_name: "4.4", tooth_state_id: 1 },
        { fdi_name: "4.5", tooth_state_id: 1 },
        { fdi_name: "4.6", tooth_state_id: 1 },
        { fdi_name: "4.7", tooth_state_id: 1 },
        { fdi_name: "4.8", tooth_state_id: 1 }
    ]

    const [odontogramId, setOdontogramId] = useState(0)
    const [teethStatus, setTeethStatus] = useState(initialTeethStatus)

    const getOdontogramId = async () => {
        await Axios.get("odontogram/" + location.state.anamId)
            .then(response => {
                if (response.data.result[0] !== undefined) {
                    setOdontogramId(response.data.result[0].odontogram_id)
                    getTeethStatus(response.data.result[0].odontogram_id)
                }
                else {
                    addTeethStatus(teethStatus);
                }
            })
    }

    const handleCreateOdontogram = () => {
        if (odontogramId === 0) {
            addTeethStatus(teethStatus)
        }
        setShowModal(true)
    }

    const addTeethStatus = async (initialTeeth) => {
        const res = await Axios.post("odontogram",
            {
                dentalAnamnesisId: location.state.anamId,
                teethState: initialTeeth,
                odontogramId: odontogramId,
            }
        )
        if (!res.data.error) {
            getOdontogramId()
        }
    }

    const updateOdontogram = async (id, updatedOdontogram) => {
        const toastId = toast.loading('Guardando...', { removeDelay: 0 });
        const res = await Axios.put("odontogram",
            {
                dentalAnamnesisId: location.state.anamId,
                odontogramId: id,
                teethState: updatedOdontogram
            }
        )
        toast.remove(toastId);
        if (!res.data.error) {
            toast.success("¡Datos guardados correctamente!", {
                id: 'success'
            })
        }
        else {
            toast.error("¡Error al guardar los datos!")
        }
    }

    const getTeethStatus = async (odontogramId) => {
        await Axios.get("odontogram/teeth/" + odontogramId).then(response => {
            if (response.data.result[0] !== undefined) {
                setTeethStatus(response.data.result)
            }
        })
    }

    const getTeethSelected = () => {
        var indices = "";
        Object.entries(teethStatusList).forEach(([key, value], index) => {
            indices += value + ", ";
        });
        indices = indices.slice(0, -2);
        return indices;
    }

    const saveData = async () => {
        statusRef.current.handleStatus();
        setShouldUpdate(!shouldUpdate);
    };

    useEffect(() => {
        if (odontogramId !== 0 && toothStatus !== TOOTH_STATUS_DEFAULT && teethStatusList.length != 0) {
            updateTeethOdontogram(toothStatus);
            const newTeethStatus = teethStatus.map((tooth, index) =>
                teethStatusList.includes(tooth.fdi_name) ? { ...tooth, tooth_state_id: toothState } : tooth
            )
            updateOdontogram(odontogramId, newTeethStatus);
            getOdontogramId();
            setTeethStatusList([]);
            setStrokeWidthList({});
            setCurrentTooth('');
        }
    }, [shouldUpdate]);

    useEffect(() => {
        getOdontogramId();
        resizeCanvas();
    }, []);

    return (
        <Container
            className="mt-1">
            <Modal
                size="lg"
                centered
                isOpen={showModal}
                scrollable
            >
                <ModalHeader>Fantoma</ModalHeader>
                <ModalBody className="p-0">
                    <Canvas
                        className="canvas"
                        gl={{ antialias: true }}
                        shadowMap
                        pixelRatio={window.devicePixelRatio}
                        style={{ height: canvasSize.height }}
                        camera={{ fov: 55, position: [0, 0, 10] }}
                    >
                        <CameraControls minDistance={0.5} maxDistance={1.5} />
                        <spotLight intensity={0.4} position={[300, 300, 2000]} castShadow={true} />
                        <spotLight intensity={0.35} position={[-3000, 1300, -1000]} castShadow={true} />
                        <ambientLight intensity={0.07} />
                        <Suspense fallback="loading">
                            <FantomaOdontogram scale={[10, 10, 10]} position={[0, 0, 0]} teeth={teethStatus} opacity={gumOpacity ? 1 : 0.8} />
                        </Suspense>
                    </Canvas>
                    <ButtonGroup style={{ zIndex: 100, position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Button onClick={() => setGumOpacity(!gumOpacity)} color="primary" size="lg" id="opacity"><Icon className="fas" />
                            <Tooltip
                                placement="top"
                                isOpen={opacityTooltipOpen}
                                target={"opacity"}
                                toggle={() => setOpacityTooltipOpen(!opacityTooltipOpen)}
                            >
                                Opacidad
                            </Tooltip>
                        </Button>
                    </ButtonGroup>
                </ModalBody>
                <ModalFooter className="p-1">
                    <Button color="secondary" onClick={() => {
                        setShowModal(false);
                    }}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                size="lg"
                centered
                isOpen={showSummaryModal}
                scrollable
            >
                <ModalHeader>Tabla resumen según estados de dientes</ModalHeader>
                <ModalBody className="p-0">
                    <SummaryTable
                        odontogramStatus={teethStatus}
                        patient={location.state}
                    />
                </ModalBody>
                <ModalFooter className="p-1">
                    <Button color="secondary" onClick={() => {
                        setShowSummaryModal(false);
                    }}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <ButtonToolbar>
                <ButtonGroup style={{ zIndex: 100, position: 'absolute', top: '90%', left: '75%', transform: 'translate(-50%, -50%)', width: '35%' }}>
                    <Button onClick={() => saveData()} style={{ margin: '10px', transition: 'transform 0.2s ease-in-out', transform: isAnimated ? 'scale(0.9)' : 'scale(1)' }} color="primary" size="lg" id="pieces-selector">Guardar <i className="fas fa-save"></i>
                    </Button>
                    <Button onClick={() => handleCreateOdontogram()} style={{ margin: '10px' }} color="primary" size="lg" id="pieces-selector">Fantoma <i className="fas fa-tooth"></i>
                    </Button>
                    <Button onClick={() => setShowSummaryModal(true)} style={{ margin: '10px' }} color="primary" size="lg" id="pieces-selector">Resumen <i className="fas fa-table"></i>
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
            {!update
                &&
                <ButtonGroup style={{ zIndex: 100, position: 'absolute', top: '110%', left: '94%', transform: 'translate(-100%, -50%)' }}>
                    <Button color="primary" size="sm"><Link style={{ color: "white" }} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/examen-extra-oral"
                    }}>Extra Oral</Link>
                    </Button>
                    <Button color="primary" size="sm"><Link style={{ color: "white" }} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/examen-tejidos-blandos"
                    }}>Tejidos Blandos</Link>
                    </Button>
                    <Button color="primary" active size="sm">Odontograma</Button>
                    <Button color="info" size="sm"><Link style={{ color: "white" }} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/periodontograma"
                    }}>Periodontograma</Link>
                    </Button>
                </ButtonGroup>
            }
            <Row>
                <Col className="col-5">
                    <div className="row m-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="svg8"
                            width="100%"
                            height="600"
                            version="1.1"
                            viewBox="0 0 125.857 200.777"
                        >
                            <Teeth
                                teethStatus={teethStatus}
                                setTeethStatus={setTeethStatus}
                                teethStatusList={teethStatusList}
                                setTeethStatusList={setTeethStatusList}
                                strokeWidthList={strokeWidthList}
                                setStrokeWidthList={setStrokeWidthList}
                                setCurrentTooth={setCurrentTooth}
                            />
                            <Legend
                                teethStatus={teethStatus}
                                setTeethStatus={setTeethStatus}
                                teethStatusList={teethStatusList}
                                setTeethStatusList={setTeethStatusList}
                                setStrokeWidthList={setStrokeWidthList}
                            />
                        </svg>
                    </div>
                </Col>
                <Col className="col-7">
                    <Collapse className="col-12" isOpen={teethStatusList.length >= 1 ? true : false}>
                        <Fade in={teethStatusList.length >= 1 ? true : false}  >
                            <ToothStatus
                                ref={statusRef}
                                toothSelected={currentTooth}
                                toothStatus={toothStatus}
                                toothState={toothState}
                                setToothStatus={setToothStatus}
                                setTeethStatus={setTeethStatus}
                                teethStatus={teethStatus}
                                teethStatusList={teethStatusList}
                                setToothState={setToothState}
                                teethSelected={teethSelected}
                                setMessage={setMessage}
                                setIsAnimated={setIsAnimated}
                            />
                        </Fade>
                    </Collapse>
                    {message && <p style={{ position:'absolute', color: 'blue', textAlign: 'center', bottom: '80px', left:'40px' }}>{message}</p>}
                </Col>
            </Row>
        </Container >
    )
}

export default ToothPicker


const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1rem"
            height="1rem"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 90 90"
            xmlSpace="preserve"
        >
            <path
                fill='white'
                d="M69.772 20.224C63.521 7.917 50.719 0 36.883 0 16.546 0 0 16.546 0 36.883c0 13.836 7.917 26.638 20.224 32.889C26.32 81.761 38.771 90 53.117 90 73.454 90 90 73.454 90 53.117c0-14.347-8.239-26.797-20.228-32.893zm-16.717 1.011l15.71 15.71a31.866 31.866 0 01-.66 6.411L46.644 21.895a31.874 31.874 0 016.411-.66zm-29.28 44.35a31.458 31.458 0 01-1.51-4.43l6.59 6.59a31.559 31.559 0 01-4.871-1.698c-.069-.154-.143-.306-.209-.462zm13.17 3.18l-15.71-15.71c.004-2.196.231-4.34.66-6.412l21.461 21.461c-2.071.43-4.215.657-6.411.661zm11.833-2.309L23.544 41.222a31.773 31.773 0 012.365-4.707l27.577 27.577a31.925 31.925 0 01-4.708 2.364zm8.797-5.345L28.889 32.425a32.082 32.082 0 013.536-3.536l28.686 28.686a32.082 32.082 0 01-3.536 3.536zm6.517-7.626L36.515 25.908a31.816 31.816 0 014.707-2.364l25.234 25.234a31.768 31.768 0 01-2.364 4.707zm-2.937-31.22a31.469 31.469 0 014.892 1.719 31.559 31.559 0 011.698 4.871zM53.117 85c-10.216 0-19.319-4.835-25.158-12.331a36.842 36.842 0 008.924 1.098c20.338 0 36.884-16.546 36.884-36.883 0-3.047-.377-6.028-1.098-8.924C80.165 33.798 85 42.901 85 53.117 85 70.697 70.697 85 53.117 85z">
            </path>
        </svg>
    );
}