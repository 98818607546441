import React, { useEffect } from "react";
import { useState } from "react";
import Axios from "helpers/axiosConfig";
import galleta from "js-cookie";
import { Button } from "reactstrap";

function SyncGoogleButton() {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const addGoogleAccount = async () => {
    try {
      const response = await Axios.post("oauth2/google");
      console.log(response);
      if (response.status === 200) {
        window.location.href = response.data.redirectUrl; // Redirigir a Google OAuth URL
      } else {
        console.error("Error al iniciar OAuth:", await response.text());
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const fetchHasGoogleAccount = async () => {
    const userId = galleta.getJSON("user").user.result[0].user_id;
    try {
      setLoading(true);
      const response = await Axios.get("oauth2/has-token/" + userId);
      console.log(response);
      if (response.data.success) {
        setEmail(response.data.email);
      } else {
        setEmail(null);
      }
    } catch (error) {
      console.error("Error fetching backend response:", error);
      setEmail(null);
    } finally {
      setLoading(false);
    }
  };

  const removeGoogleAccount = async () => {
    const userId = galleta.getJSON("user").user.result[0].user_id;
    try {
      setLoading(true);
      const response = await Axios.delete("oauth2/delete-token/" + userId);
      if (response.status === 200) {
        await fetchHasGoogleAccount();
      }
    } catch (error) {
      console.error("Error fetching backend response:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchHasGoogleAccount();
    }
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Button color="primary" disabled>
          <i className="fas fa-sync-alt"></i>
          <span>Sincronizando...</span>
        </Button>
      </>
    );
  }

  if (email === null) {
    return (
      <>
        <Button color="primary" onClick={addGoogleAccount}>
          <i className="fas fa-sync-alt"></i>
          <span>Sincronizar Google Calendar</span>
        </Button>
      </>
    );
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center text-muted">
        <i className="fa fa-sync-alt"></i>
        <span className="ml-2">Cuenta sincronizada</span>
      </div>
      <span>{email}</span>
      <br />
      <Button color="danger" size="sm" onClick={removeGoogleAccount}>
        Quitar cuenta
      </Button>
    </div>
  );
}

export default SyncGoogleButton;
