import React from "react";
import { Button } from "reactstrap";
import galleta from "js-cookie";
import { getSecretCode } from "../api/SecretCode";

export default function AccessButton({
  conferenceTarget,
  buttonText = "Ingresar a video Conferencia",
  size,
  disabled = false,
}) {
  const handleClick = async () => {
    let userInfo = galleta.get("user");

    if (typeof userInfo === "string") {
      userInfo = JSON.parse(userInfo);
    }

    const token = await getSecretCode(
      userInfo.user.token,
      conferenceTarget.name,
      userInfo.user.result[0].user_id
    );
    console.log(token);
    localStorage.setItem(
      "videoconferenceSettings",
      JSON.stringify({
        id: conferenceTarget.id,
        name: conferenceTarget.name,
        username: userInfo.user.result[0].names ?? "Usuario",
        token: token,
      })
    );

    window.open("/atención/videoconferencia", "_blank");
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      size={size}
      disabled={disabled}
    >
      <i className="fas fa-video mr-2" />
      {buttonText}
    </Button>
  );
}
